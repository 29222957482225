import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import YToken from "../../../../assets/img/yToken.png";
import XToken from "../../../../assets/img/xToken.png";
import Eth from "../../../../assets/img/ftm.png";

interface Props {
    yInDollars: string;
    xInEth: string;
    EthInDollars?: string;
}

const SidebarPrices: React.FC<Props> = ({ yInDollars, xInEth, EthInDollars }) => {
    const { color } = useContext(ThemeContext);

    const theme = {
        backgroundColor: color.theme.light.sidebar.priceBackground,
        color: color.theme.light.white,
    }

    return (
        <>
            <TokenPrice theme={theme}>
                    TOKEN PRICES
                </TokenPrice>
                <SidebarPrice theme={theme}>
                    <SidebarLogo src={YToken}/>
                    ${yInDollars}
                </SidebarPrice>
                <SidebarPrice theme={theme}>
                    <SidebarLogo src={XToken}/>
                    {xInEth}
                </SidebarPrice>
                <SidebarPrice theme={theme}>
                    <SidebarLogo src={Eth}/>
                    ${EthInDollars}
                </SidebarPrice>
        </>
    )
};

interface ThemeProps {
    theme: any;
}

const SidebarLogo = styled.img`
    width: 36px;
    aspect-ratio: auto 36 / 36;
    height: 36px;
    display: inline-block;
    border-radius: 0.5em;
    margin-right: 10px;
`

const SidebarPrice = styled.div<ThemeProps>`
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 5px;
    border-radius: 10px;
    background-color: ${(props) => props.theme.backgroundColor};
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => props.theme.color};
    @media screen and (min-width: 992px) {
        display: none;
    };
    :not(:last-child) {
        margin-bottom: 15px;
    };
`

const TokenPrice = styled.div<ThemeProps>`
    display: block;
    padding-bottom: 16px;
    font-size: 16px;
    font-weight: bold;
    color: ${(props) => props.theme.color};
    @media screen and (min-width: 992px) {
        display: none;
    }
`

export default SidebarPrices;