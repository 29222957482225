import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import YToken from "../../../../assets/img/yToken.png";
import XToken from "../../../../assets/img/xToken.png";
import Eth from "../../../../assets/img/eth.png";

interface Props {
    yInDollars: string;
    xInEth: string;
    EthInDollars?: string;
}

const HeaderPriceButtons: React.FC<Props> = ({ yInDollars, xInEth, EthInDollars }) => {
    const { color } = useContext(ThemeContext);

    const theme = {
        font: {
            size: {
                16: "16px"
            }
        },
        color: {
            white: color.theme.light.white,
            background: color.theme.light.header.priceBackground,
        }
    }
    
    return (
        <>
            <HeaderLogoPriceButton theme={theme} disabled={true} >
                <HeaderLogoPriceImg src={YToken} />
                <HeaderLogoPriceSpan theme={theme}>
                    ${yInDollars}
                </HeaderLogoPriceSpan>
            </HeaderLogoPriceButton>

            <HeaderLogoPriceButton theme={theme} disabled={true} >
                <HeaderLogoPriceImg src={XToken} />
                <HeaderLogoPriceSpan theme={theme}>
                    {xInEth}
                </HeaderLogoPriceSpan>
            </HeaderLogoPriceButton>

            <HeaderLogoPriceButton theme={theme} disabled={true} >
                <HeaderLogoPriceImg src={Eth} />
                <HeaderLogoPriceSpan theme={theme} >
                    ${EthInDollars}
                </HeaderLogoPriceSpan>
            </HeaderLogoPriceButton>
        </>
    )
};

interface ThemeProps {
    theme?: any;
}

const HeaderLogoPriceButton = styled.button<ThemeProps>`
    display: none;
    :not(:disabled) {
        cursor: pointer;
    };
    @media screen and (min-width: 1400px) {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding: 5px 12px 5px 5px;
        height: 46px;
        border-radius: 10px;
        background-color: ${(props) => props.theme.color.background};
        font-family: inherit;
        border: none;
    };
`

const HeaderLogoPriceImg = styled.img`
    display: inline-block;
    border-radius: 50%;
    border: none;
    width: 30px;
    aspect-ratio: auto 30 / 30;
    height: 30px;
    @media screen and (min-width: 992px) {
        margin-right: 8px;
    };
`

const HeaderLogoPriceSpan = styled.span<ThemeProps>`
    display: none;
    font-size: ${(props) => props.theme.font.size[16]};
    font-weight: 500;
    color: ${(props) => props.theme.color.white};
    @media screen and (min-width: 992px) {
        display: inline;
    };
`

export default HeaderPriceButtons;