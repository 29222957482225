import React from 'react';
import styled from 'styled-components';

interface TitleProps {
    children?: React.ReactNode;
}

const SmallContent: React.FC<TitleProps> = ({ children }) => <Div> {children} </Div>;

const Div = styled.div`
    margin: 0px auto;
    display: block;
    @media screen and (min-width: 992px) {
        width: 650px;
    }
`
export default SmallContent;