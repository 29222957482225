import React, { useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';

interface ButtonProps {
  children?: React.ReactNode;
  disabled?: boolean;
  href?: string;
  onClick?: () => void;
  // OR handleClick(e: MouseEvent<HTMLButtonElement>) => void for events
  size?: 'sm' | 'md' | 'lg';
  text?: string;
  to?: string;
  errorText?: string;
  error?: boolean;
  width?: string;
  deposit?: any;
  symbol0?: string;
  colorAlert?: boolean;
  generic?: boolean;
  genericText?: string;
}

const SolidButton: React.FC<ButtonProps> = ({ generic, genericText, colorAlert, symbol0, deposit, width, children, disabled, href, onClick, size, text, to, errorText, error }) => {
  const { font, spacing, color } = useContext(ThemeContext);

  const buttonWidth = width === "auto" ? "auto" : "100%"

  let buttonSize: number;
  let buttonPadding: number;
  let fontSize: string;
  switch (size) {
    case 'sm':
      buttonPadding = spacing[4];
      buttonSize = 36;
      fontSize = font.size[14];
      break;
    case 'lg':
      buttonPadding = spacing[4];
      buttonSize = 40;
      fontSize = font.size[16];
      break;
    case 'md':
    default:
      buttonPadding = spacing[4];
      buttonSize = 42;
      fontSize = font.size[16];
  }

  const theme = {
    styledButton: {
        height: buttonSize,
        width: buttonWidth,
        padding: {
          left: buttonPadding,
          right: buttonPadding
        },
        border: {
          width: color.theme.light.borderWidth.large,
          color: colorAlert ? color.theme.light.alert : color.theme.light.primary,
          disabled: color.theme.light.disabled,
        },
        background: {
          disabled: color.theme.light.disabled,
          active: colorAlert ? color.theme.light.alert : color.theme.light.primary,
        },
        font: {
          size: fontSize,
          weight:  color.theme.light.font.weight[4],
        },
        color: {
          primary: colorAlert ? color.theme.light.alert : color.theme.light.primary,
          secondary: color.theme.light.secondary 
        }
    },
}

  const ButtonChild = useMemo(() => {
    if (to) {
      return <StyledLink to={to}>{text}</StyledLink>;
    } else if (href) {
      return (
        <StyledExternalLink href={href} target="__blank">
          {text}
        </StyledExternalLink>
      );
    } else {
      if (generic) {
        return genericText 
      } else if (!!deposit) {
        return `Input ${symbol0}` 
      } else {
        return !!error === true ? errorText : text;
      }
    }
  }, [href, text, to, error, errorText, deposit, symbol0, generic, genericText]);

  return (
            <StyledButton theme={theme} disabled={disabled} onClick={onClick}>
                <StyledDiv theme={theme}>
                    <GhostDiv theme={theme}>
                    {children}
                    {ButtonChild}
                    </GhostDiv>
                    <TextDiv theme={theme}>
                    {children}
                    {ButtonChild}
                    </TextDiv>
                </StyledDiv>
            </StyledButton>
  );
};

const StyledLink = styled(Link)`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0 ${(props) => -props.theme.spacing[4]}px;
  padding: 0 ${(props) => props.theme.spacing[4]}px;
  text-decoration: none;
`;

const StyledExternalLink = styled.a`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0 ${(props) => -props.theme.spacing[4]}px;
  padding: 0 ${(props) => props.theme.spacing[4]}px;
  text-decoration: none;
`;

interface ThemeProps {
  theme: any;
  disabled?: boolean;
}

const StyledDiv = styled.div<ThemeProps>`
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  text-decoration: none;
  font-size: ${(props) => props.theme.styledButton.font.size};
`;

const TextDiv = styled.div<ThemeProps>`
  text-decoration: none;
  display: block;
  opacity: 1;
  color: ${(props) => props.theme.styledButton.color.secondary};
  webkit-transition: all 400ms ease;
  transition: all 400ms ease;
`;


const GhostDiv = styled.div<ThemeProps>`
  position: absolute;
  top: -80%;
  opacity: 0;
  color: ${(props) => props.theme.styledButton.color.primary};
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
`;

const StyledButton = styled.button<ThemeProps>`
  display: flex;
  cursor: pointer;
  pointer-events: ${(props) => (!props.disabled ? undefined : 'none')};
  height: ${(props) => props.theme.styledButton.height}px;
  width: ${(props) => props.theme.styledButton.width};
  align-items: center;
  justify-content: center;
  outline: none;
  padding-left: ${(props) => props.theme.styledButton.padding.left}px;
  padding-right: ${(props) => props.theme.styledButton.padding.right}px;

  border-radius: 0.5em;
  box-shadow: inset 0 0 0 ${(props) => props.theme.styledButton.border.width} ${(props) => (!props.disabled ? props.theme.styledButton.border.color : props.theme.styledButton.border.disabled)};

  -webkit-box-align: center;
  -webkit-box-pack: center;
  border: none;

  z-index: 1;
  background-color: ${(props) => (!props.disabled ? props.theme.styledButton.background.active : props.theme.styledButton.background.disabled)};
  
  font-size: ${(props) => props.theme.styledButton.font.size};
  font-weight: ${(props) => props.theme.styledButton.font.weight};

  position: relative;
  transition: all 500ms cubic-bezier(.19, 1, .22, 1);
  
  :hover {
      background-color: transparent;
  };
  :hover ${TextDiv} {
      opacity: 0;
      transform: translateY(80%);
  };
  :hover ${GhostDiv} {
      opacity: 1;
      transform: translateY(80%);
  }
`;

export default SolidButton;
