import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import MetamaskFox from '../../assets/img/metamask-fox.svg';

interface props {
    onClick?: () => void;
}

const MetamaskButton: React.FC<props> = ({ onClick }) => {
    const { color, font } = useContext(ThemeContext);

    const theme = {
        light: {
            primary: color.theme.light.primary,
            secondary: color.theme.light.secondary,
             transparent: {
        10: color.theme.light.transparent[10]
      }
        },
        fontWeight: {
            400: color.theme.light.font.weight[0],
            500: color.theme.light.font.weight[1],
            600: color.theme.light.font.weight[2],
            700: color.theme.light.font.weight[3]
        },
        font: font,
        borderWidth:  color.theme.light.borderWidth.xlarge,
        metamaskButtonHeight: "42px",
        borderRaidus: {
            0: "0em",
            1: color.theme.light.borderRaidus[1],
            2: "0.5em",
          },
    }

    return (
        
            <Metamask theme={theme} onClick={onClick}>
                +
                <img alt="metamask fox" style={{ margin: "0px 0px 0px 5px", cursor: "pointer", width: '24px' }} src={MetamaskFox} />
            </Metamask>
        
    )
};


interface MetamaskButtonProps {
    theme: any;
}

const Metamask = styled.button<MetamaskButtonProps>`
    cursor: pointer;
    background-image: none;
    border: ${(props) => props.theme.borderWidth} solid ${(props) => props.theme.light.primary};
    font-size: ${(props) => props.theme.font.size.xxxlarge};
    font-weight: ${(props) => props.theme.fontWeight[400]};
    margin-right: auto;
    position: relative;
    z-index: 1;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 18px;
    border-radius: ${(props) => props.theme.borderRaidus[1]};
    color: ${(props) => props.theme.light.primary};
    line-height: 1;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    height: ${(props) => props.theme.metamaskButtonHeight};
    background-position: -2px 0%;
    background-size: 220% 100%;
    transition: all 0.2s ease-in-out 0s;
    font-family: inherit;
    background: transparent;
    :before {
        background-color: transparent;
    };
    :before {
        content: "";
        position: absolute;
        inset: 1px;
        border-radius: ${(props) => props.theme.borderRaidus[1]};
        z-index: -1;
    };
    :hover:before {
        display: none;
    };
    :hover {
        background: ${(props) => props.theme.light.transparent[10]};
        background-position-x: 95%;
    };
`;

export default MetamaskButton;
