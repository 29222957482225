import config from './config';
import { yToken as yTokenTesting, xToken as xTokenTesting } from './liquid-finance/deployments/deployments.testing.json';
import { yToken as yTokenProd, xToken as xTokenProd } from './liquid-finance/deployments/deployments.mainnet.json';
import xTokenPng from "./assets/img/xToken.png"
import yTokenPng from "./assets/img/yToken.png"
import ethPng from "./assets/img/eth.png"
import { AbstractConnector } from '@web3-react/abstract-connector';
import {
  // fortmatic,
  injected,
  // portis,
  // walletconnect,
  walletlink,
} from './connectors';


let yToken;
let xToken;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  yToken = yTokenTesting;
  xToken = xTokenTesting;
} else {
  yToken = yTokenProd;
  xToken = xTokenProd;
}

export type Constants = {
    xBuyAddress: string;
    yBuyAddress: string;
    xAddLiqAddress: string;
    yAddLiqAddress: string;
    xViewContract: string;
    yViewContract: string;
    docs: string;
    discord: string;
    twitter: string;
    xBuyAddressXcal: string;
  };

export const constant: Constants = {
    xBuyAddress: "https://app.sushi.com/swap?chainId=42161&inputCurrency=ETH&outputCurrency=0x73700aeCfC4621E112304B6eDC5BA9e36D7743D3",
    yBuyAddress: "https://app.sushi.com/swap?chainId=42161&inputCurrency=ETH&outputCurrency=0x93C15cd7DE26f07265f0272E0b831C5D7fAb174f",
    xAddLiqAddress: "https://app.sushi.com/legacy/add/ETH/0x73700aeCfC4621E112304B6eDC5BA9e36D7743D3?chainId=42161",
    yAddLiqAddress: "https://app.sushi.com/legacy/add/ETH/0x93C15cd7DE26f07265f0272E0b831C5D7fAb174f?chainId=42161",
    xViewContract:  config.blockExplorerUrl + "token/" + xToken.address,
    yViewContract: config.blockExplorerUrl + "token/" + yToken.address, 
    docs: "https://docs.liquidfinance.io/",
    discord: "https://discord.gg/Hyes3eGNrJ",
    twitter: "https://twitter.com/FinanceLiquid",
    xBuyAddressXcal: "https://app.3xcalibur.com/swap/single"
};

export type Assets = {
  xToken: any;
  yToken: any;
  eth: any;
  logo: any;
};

export const assets: Assets = {
  xToken: xTokenPng,
  yToken: yTokenPng,
  eth: ethPng,
  logo: xTokenPng,
}

export const NetworkContextName = 'NETWORK';

export interface WalletInfo {
  connector?: AbstractConnector;
  name: string;
  iconName: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  // WALLET_CONNECT: {
  //   connector: walletconnect,
  //   name: 'WalletConnect',
  //   iconName: 'walletConnectIcon.svg',
  //   description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
  //   href: null,
  //   color: '#4196FC',
  //   mobile: true,
  // },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5',
  },
  // COINBASE_LINK: {
  //   name: 'Open in Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Open in Coinbase Wallet app.',
  //   href: 'https://go.cb-w.com/mtUDhEZPy1',
  //   color: '#315CF5',
  //   mobile: true,
  //   mobileOnly: true
  // },
  // FORTMATIC: {
  //   connector: fortmatic,
  //   name: 'Fortmatic',
  //   iconName: 'fortmaticIcon.png',
  //   description: 'Login using Fortmatic hosted wallet',
  //   href: null,
  //   color: '#6748FF',
  //   mobile: true,
  // },
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconName: 'portisIcon.png',
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true,
  // },
};

// export default constant;
