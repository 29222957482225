import React, { useState, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Logo from '../../assets/img/Logo.png';
import WalletConnectButton from "./components/WalletConnectButton";
// import SidebarWallet from "./components/AccountButton";
import { BurgerButton, HeaderDropdownMore, HeaderPriceButtons, SidebarMore, NavList } from "./components/headerComponents";
import {constant} from "../../constants";

import useYTokenPriceDollars from '../../hooks/useYTokenPriceDollars';
import useEthPriceDollars from '../../hooks/useEthPriceDollars';
import useXTokenInEth from '../../hooks/useXTokenInEth';

const Header: React.FC= () => {
  const { color } = useContext(ThemeContext);
  const [sidebar, setSidebar] = useState(false); // false closed true open
  const [dropdown, setDropdown] = useState(false);
  const [sidebarMore, setSidebarMore] = useState(false);

  const yTokenPrice = useYTokenPriceDollars();
  const ethPriceDollars = useEthPriceDollars();
  const xTokenInEth = useXTokenInEth();

// handles click away listener for dropdown menu
window.addEventListener('click', function(e: MouseEvent){   
    if (dropdown === true) {
        if (document.getElementById('dropdownDiv').contains(e.target as HTMLElement)) {
             return
        } else if (!document.getElementById('dropdownMore').contains(e.target as HTMLElement)) {
            setDropdown(false);
        }
    }
});

const theme = {
    backgroundColor: color.theme.light.altPrimary,
    backgroundColorLight: color.theme.light.header.backgroundLight,
    backgroundTrans: color.theme.light.transparent[27],
    grey: color.theme.light.header.grey,
    secondary: color.theme.light.secondary,
}

const yInDollars = useMemo(() => (yTokenPrice ? yTokenPrice : "-"), [yTokenPrice])
const ethInDollars = useMemo(() => (ethPriceDollars ? ethPriceDollars : "-"), [ethPriceDollars])
const xInEth = useMemo(() => (xTokenInEth ? xTokenInEth : "-"), [xTokenInEth])

const yDollar = yInDollars === "-" ? "-" : Number(yInDollars).toLocaleString("en-US", {maximumFractionDigits: 0, useGrouping: true})
const xEth = xInEth === "-" ? "-" : `${Number(xInEth).toLocaleString("en-US", {maximumFractionDigits: 3, useGrouping: true})} ETH`
const ethDollar = ethInDollars === "-" ? "-" : Number(ethInDollars).toFixed(0)

    return (
        <Container theme={theme}>

            <BurgerButton onClick={() => setSidebar(true)} />

            <LogoLink href="/" >
                <LogoImg src={Logo} />
            </LogoLink>

            <TextLinkDiv theme={theme} sidebarOpen={sidebar}>
                <TextLinkInnerDiv theme={theme}>
                    <LogoDiv >
                        <LogoLinkSideBar>
                            <LogoLinkSideBarImg src={Logo} />
                        </LogoLinkSideBar>
                    </LogoDiv>
                    <SidebarWalletDiv>
                        {/* <SidebarWallet /> Removed because its broken, need to fix in future*/}
                    </SidebarWalletDiv>
                    <MarginDiv >
                    <NavList onClick={() => setSidebar(false)}>
                        <SidebarMore
                            docsHref={constant.docs} twitterHref={constant.twitter} discordHref={constant.discord}
                            open={sidebarMore} onClick={() => setSidebarMore(!sidebarMore)} />
                    </NavList>
                    </MarginDiv>
                    {/* <Seperator theme={theme} />
                    <SidebarPrices
                        yInDollars={yDollar}
                        xInEth={xEth}
                        EthInDollars={ethDollar}
                    /> */}
                </TextLinkInnerDiv>
                <TextLinkDivOverlay onClick={() => setSidebar(false)} />
            </TextLinkDiv>

            <HeaderLogoPrices>
                <HeaderPriceButtons yInDollars={yDollar} xInEth={xEth} EthInDollars={ethDollar} />
                <WalletConnectButton />
                <HeaderDropdownMore
                    onClick={() => setDropdown(!dropdown)}
                    open={dropdown} idDropdown="dropdownMore" idButton="dropdownDiv"
                    docsHref={constant.docs} twitterHref={constant.twitter} discordHref={constant.discord}
                />
            </HeaderLogoPrices>
        </Container>
    );
}; 

interface ThemeProps {
    theme: any;
}

const MarginDiv = styled.div`
    margin-top: 20px;
    @media screen and (min-width: 1050px) {
        margin-top: 0px;
    }
`

const SidebarWalletDiv = styled.div`
    display: flex;
    @media screen and (min-width: 1050px) {
        display: none;
    };
`;

const HeaderLogoPrices = styled.div`
    display: block;
    margin-left: auto;
    display: flex;
    gap: 0px;
    @media screen and (min-width: 1050px) {
        gap: 8px;
    };

`

// const Seperator = styled.div<ThemeProps>`
//     display: block;
//     margin: 10px 0px 20px;
//     width: 100%;
//     height: 1px;
//     border-top: 1px dashed ${(props) => props.theme.grey};
//     @media screen and (min-width: 992px) {
//         display: none;
//     }
// `

const LogoLinkSideBarImg = styled.img`
    margin-bottom: 15px;
    width: 140px;
`;

const LogoLinkSideBar = styled.a`
    text-decoration: none;
    color: -webkit-link;
    text-decoration: underline;
`;

const LogoDiv = styled.div`
    display: block;    
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    @media screen and (min-width: 1050px) {
        display: none;
    }
`;

const TextLinkInnerDiv = styled.div<ThemeProps>`
    display: block;    
    position: relative;
    width: 80%;
    height: 100%;
    padding: 20px 20px 40px;
    background-color: ${(props) => props.theme.backgroundColor};
    overflow: auto;
    background-size: 164px;
    background-repeat: no-repeat;
    background-position: left bottom;
    z-index: 1001;
    @media screen and (min-width: 1050px) {
        width: 100%;
        padding: 0px 21px;
        overflow: unset;
        background: none;
    };
    ::-webkit-scrollbar {
        width: 5px;
    };
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: ${(props) => props.theme.backgroundColorLight};
    };
    .bEQnWh::-webkit-scrollbar-track {
        border-radius: 5px;
    };

`;

interface TextLinkDivProps {
    sidebarOpen: boolean;
    theme: any;
}

const TextLinkDiv = styled.div<TextLinkDivProps>`
display: flex;
    position: fixed;
    margin: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    min-width: 300px;
    height: 100%;
    -webkit-box-pack: start;
    justify-content: flex-start;
    transform: translateX( ${(props) => props.sidebarOpen ? "0%" : "-100%"});
    transition: transform 0.2s linear 0s;
    z-index: 1000;
    background-color: ${(props) => props.theme.backgroundTrans};
    @media screen and (min-width: 1050px) {
        width: fit-content;
        height: fit-content;
        position: inherit;
        display: flex;
        z-index: 1;
        transform: none;
        background-color: transparent;
        padding: 0px;
    }
`;


const TextLinkDivOverlay = styled.div`
    display: inline-block;    
    position: relative;
    width: 50%;
    height: 100%;
    z-index: 1001;   
    background: transparent;
    @media screen and (min-width: 1050px) {
        display: none;
    };
`;

const LogoImg = styled.img`
    width: 180px;
    text-decoration: none;
    color: -webkit-link;
    cursor: pointer;
    @media screen and (min-width: 1050px) {
        width: 180px;
        margin-bottom: 0px;
    }
`;

const LogoLink = styled.a`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    @media screen and (min-width: 1050px) {
        position: relative;
        z-index: 10002;
    }
`;

const Container = styled.div<ThemeProps>`
    display: flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    height: 80px;
    padding: 0px 15px;
    background-color: ${(props) => props.theme.backgroundColor};
    @media screen and (min-width: 1050px) {
        padding: 0px 32px;
        background-color: ${(props) => props.theme.backgroundColor};
    }
`;

export default Header;