import { useEffect, useState } from 'react';
import useLiquidFinance from './useLiquidFinance';
// import config from '../config';
import useRefresh from './useRefresh';

const useYTokenPriceDollars = () => {
  const [yPrice, setYPrice] = useState("0");
  const liquidFinance = useLiquidFinance();
  // const [render, setRender] = useState(0);
  const { slowRefresh } = useRefresh();
  
  // const fetchPrice = useCallback(async () => {
  //   setYPrice(await liquidFinance.getYPriceDollars());
  // }, [liquidFinance]);


  // useEffect(() => {
  //   async function fetch(){
  //     if (!!liquidFinance) {
  //     try {
  //       setYPrice(await liquidFinance.getYPriceDollars());
  //     }
  //     catch(err){
  //       console.error(`useYTokenPriceDollars(): failure ${err}`)
  //     }
  //   } else return }

  //   if (render === 0) {
  //       fetchPrice().catch((err) => console.error(`Failed to fetch token balance: ${err.stack}`));
  //     let refreshInterval = setInterval(fetchPrice, config.refreshInterval);
  //     setRender(1);
  //     return () => clearInterval(refreshInterval);
  //   } else {
  //     fetch()
  //   }
  // }, [render, fetchPrice, liquidFinance, fastRefresh]);

  useEffect(() => {
    async function fetchYTokenPrice(){
      if (!!liquidFinance) {
      try {
        setYPrice(await liquidFinance.getYPriceDollars());
      }
      catch(err){
        console.error(`useYTokenPriceInDollars(): failure ${err}`)
      }
    } else return }
    fetchYTokenPrice();
  }, [setYPrice, liquidFinance, slowRefresh]);

  return yPrice;
};

export default useYTokenPriceDollars;
