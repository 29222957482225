import { Token as CoreToken } from '@sushiswap-core/sdk';
import { assets } from "../constants";
import { Configuration, } from './config';
import { BigNumber } from 'ethers';
import { Contract, ethers, utils } from 'ethers';
import ERC20 from './ERC20';
import IUniswapV2PairABI from './IUniswapV2Pair.abi.json';
import {
  ContractName, AltTreasuryStratType, TokenStat, FarmsRewards, BondData,
  MintData, MintDataBN, Bank, ZapData, mintRedeemActive, arbCalc,
  FarmsRewardsBN, PoolStatsBN, StakingData, TreasuryData, UserInfo, PresaleData, homeStaking, HomePoolStatsBN, HomeBondData, PrintRateType,
  Immutables, State } from './types';
import config from '../config';
import invariant from 'tiny-invariant';
import { getBalance2 } from '../utils/formatBalance';
import { getDisplayString } from "../utils/displayStringFormat";
import { getDefaultProvider } from '../utils/provider';
import { TransactionResponse } from '@ethersproject/providers';

import { Pool } from '@uniswap/v3-sdk';
import { Token } from '@uniswap/sdk-core';
import { abi as IUniswapV3PairABI } from '@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json'

import CoinGecko from "coingecko-api";

/**
 * An API module of Liquid Finance contracts.
 * All contract-interacting domain logic should be defined in here.
 */

export class LiquidFinance {
  myAccount?: string;
  provider: ethers.providers.Web3Provider;
  signer?: ethers.Signer;
  config: Configuration;
  contracts: { [name: string]: Contract };
  externalTokens: { [name: string]: ERC20 };
  CoinGeckoClient: any;
  //dexInfo: { _hexadem: string, _factory: string }


  LIQDWETH_LP: Contract;
  lqETHWETH_LP: Contract;
  USDCWETH_LP: Contract;
  wstEthWETH_LP: Contract;
  wstEthWETH_LP2: Contract;
  ldoWETH_LP: Contract;
  xCal_lqETHWETH_LP: Contract;
  xCal_BTClqETH_LP: Contract;
  btcEth_LP: Contract;
  LIQD: ERC20;
  lqETH: ERC20;
  ETH: ERC20;
  BTC: ERC20;
  wstETH: ERC20;

  constructor(cfg: Configuration) {

    this.CoinGeckoClient = new CoinGecko();
    // deployments is the abi object from config
    const { deployments, externalTokens } = cfg;
    // Get default provider fetches provider using ethers library
    const provider = getDefaultProvider();

    // loads contracts from deployments
    this.contracts = {};
    // name and deployment are arbitrary variable names
    for (const [name, deployment] of Object.entries(deployments)) {
      // name an array of JSON object, deployment is the object (constract name and abi in this case)
      this.contracts[name] = new Contract(deployment.address, deployment.abi, provider);
    }
    this.externalTokens = {};
    for (const [symbol, [address, decimal]] of Object.entries(externalTokens)) {
      this.externalTokens[symbol] = new ERC20(address, provider, symbol, decimal);
    }
    // ERC20 
    this.LIQD = new ERC20(deployments.yToken.address, provider, 'LIQD');
    this.lqETH = new ERC20(deployments.xToken.address, provider, 'lqETH');
    this.ETH = this.externalTokens['WETH'];
    this.BTC = this.externalTokens['BTC'];
    this.wstETH = this.externalTokens['wstETH'];

    // Uniswap V2 Pair
    this.LIQDWETH_LP = new Contract(externalTokens['LIQD-WETH-LP'][0], IUniswapV2PairABI, provider);
    this.lqETHWETH_LP = new Contract(externalTokens['WETH-lqETH-LP'][0], IUniswapV2PairABI, provider);
    this.USDCWETH_LP = new Contract(externalTokens['USDC-WETH-LP'][0], IUniswapV2PairABI, provider);
    this.wstEthWETH_LP = new Contract(externalTokens['WETH-wstETH-LP'][0], IUniswapV3PairABI, provider);
    this.wstEthWETH_LP2 = new Contract(externalTokens['WETH-wstETH-LP2'][0], IUniswapV3PairABI, provider);
    // this.balWETH_LP = new Contract(externalTokens['WETH-wstETH-LP'][0], IUniswapV3PairABI, provider);
    this.ldoWETH_LP = new Contract(externalTokens['WETH-LDO-LP'][0], IUniswapV3PairABI, provider);
    this.xCal_lqETHWETH_LP = new Contract(externalTokens['xCal-lqETH-WETH-LP'][0], IUniswapV2PairABI, provider);
    this.xCal_BTClqETH_LP = new Contract(externalTokens['xCal-BTC-lqETH-LP'][0],  IUniswapV2PairABI, provider);
    this.btcEth_LP = new Contract(externalTokens['btcEth_LP'][0], IUniswapV3PairABI, provider);
  
    this.config = cfg;
    this.provider = provider;

    //this.dexInfo = { _hexadem: '0xeb5c1b2a29e27754f81f0b84e76dfcfbc46db4488d11badd55ee4288f2747538', _factory: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9" };
  }

  /**
   * @param provider From an unlocked wallet. (e.g. Metamask)
   * @param account An address of unlocked wallet account.
   */
  unlockWallet(provider: any, account: string) {
    const newProvider = new ethers.providers.Web3Provider(provider, this.config.chainId);
    this.signer = newProvider.getSigner(0);
    this.myAccount = account;
//console.log("account test", account)
    for (const [name, contract] of Object.entries(this.contracts)) {
      this.contracts[name] = contract.connect(this.signer);
    }
    const tokens = [this.LIQD, this.lqETH, ...Object.values(this.externalTokens)];
    for (const token of tokens) {
      token.connect(this.signer);
    }
    this.lqETHWETH_LP = this.lqETHWETH_LP.connect(this.signer);
    this.LIQDWETH_LP = this.LIQDWETH_LP.connect(this.signer);

    console.log(`🔓 Wallet is unlocked. Welcome, ${account}!`);
  }

  /**
 * Used for checking that the account is unlocked before getting
 *  balances using hooks to prevent fails
 */

  get isUnlocked(): boolean {
    // !x = inverted boolean, !!x equals non-inverted boolean
    return !!this.myAccount;
  }

  async watchAssetInMetamask(assetName: string): Promise<boolean> {
    const { ethereum } = window as any;
    if (ethereum && ethereum.networkVersion === config.chainId.toString()) {
      let asset;
      let assetUrl;
      if (assetName === 'LIQD') {
        asset = this.LIQD;
        //console.log("log 1",asset)acco
        ////////// SETUP1234
        assetUrl = assets.yToken;
      } else if (assetName === 'lqETH') {
        asset = this.lqETH;
        //console.log("log 2", asset)
        ////////// SETUP1234
        assetUrl = assets.xToken;
      } else if (assetName === 'WETH') {
        asset = this.ETH;
        //console.log("log 3",asset)
        ////////// SETUP1234
        assetUrl = assets.eth;
      }
      await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: asset.address,
            symbol: asset.symbol,
            decimals: 18,
            image: assetUrl,
          },
        },
      });
    }
    return true;
  }


  //===================================================================
  //===================================================================
  //=================== INTERNAL HELPER FUNCTIONS =====================
  //===================================================================
  //===================================================================

    /**
   * Redundant function that calls getAddress
   * @returns LP address for a given pair of tokens
   */

  async fetchLpHardCodedAddresses(
    tokenA: CoreToken,
    tokenB: CoreToken,
  ): Promise<string> {
    invariant(tokenA.chainId === tokenB.chainId, 'CHAIN_ID')
    var address = await this.getAddress(tokenA.address, tokenB.address);
    return address;
  }

   /**
   * getAddress calls get pair from factory contract to return LP address
   * @returns LP address for a given pair of tokens
   */

  async getAddress(tokenA: string, tokenB: string): Promise<string> {
    try {
    const { factory } = this.contracts
    const address = await factory.getPair(tokenA, tokenB);
    return (address)
  } catch (err) {
    console.error(`Failed to getPair() from factory: ${err}`);
    return;
  }
  }

  async getPoolImmutables(lp: Contract) {
    const [factory, token0, token1, fee, tickSpacing, maxLiquidityPerTick] = await Promise.all([
      lp.factory(),
      lp.token0(),
      lp.token1(),
      lp.fee(),
      lp.tickSpacing(),
      lp.maxLiquidityPerTick(),
    ])
  
    const immutables: Immutables = {
      factory,
      token0,
      token1,
      fee,
      tickSpacing,
      maxLiquidityPerTick,
    }
    return immutables
  }
  
  async getPoolState(lp: Contract) {
    // const [liquidity, slot] = await Promise.all([this.wstEthWETH_LP.liquidity(), this.wstEthWETH_LP.slot0()])
    const [liquidity, slot] = await Promise.all([lp.liquidity(), lp.slot0()])
    const PoolState: State = {
      liquidity,
      sqrtPriceX96: slot[0],
      tick: slot[1],
      observationIndex: slot[2],
      observationCardinality: slot[3],
      observationCardinalityNext: slot[4],
      feeProtocol: slot[5],
      unlocked: slot[6],
    }
  
    return PoolState
  }

     /**
   * getTokenPriceFromPancakeswap returns price data for getXTokenStat and getYToken Stat. It does not use Pancakeswap
   * @param tokenContract ERC20 contract of the token of interest
   * @returns Price of token in eth to 6 decemal places as a string
   */

  async getTokenPriceFromPancakeswap(tokenContract: ERC20): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { chainId } = this.config; // must be a chain id type
    const { WETH } = this.config.externalTokens;
    const weth = new CoreToken(chainId, WETH[0], WETH[1]); // address, decimals
    const token = new CoreToken(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);

    try {
      const lpAddress = await this.fetchLpHardCodedAddresses(weth, token);
      let ethBalanceInLP = await this.ETH.balanceOf(lpAddress);
      let tokenBalanceInLP = await tokenContract.balanceOf(lpAddress);
      let ethBalanceInLPstring = getBalance2(ethBalanceInLP, weth.decimals, 6);
      let tokenBalanceInLPstring = getBalance2(tokenBalanceInLP, weth.decimals, 6);
      let price = Number(ethBalanceInLPstring) / Number(tokenBalanceInLPstring)
      return price.toFixed(6)
    } catch (err) {
      console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
      return;
    }
  }

   /**
   * getWETHPriceFromPancakeswap is a just like getTokenPriceFromPancakeswap but specific to weth. It is probably redundant and It does not use Pancakeswap
   * @returns Price of weth in dollars to > 6 decemal places as a string
   */

  async getWETHPriceFromPancakeswap(): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { WETH, USDC } = this.externalTokens;
    const usdc_eth_lp_pair = this.externalTokens['USDC-WETH-LP'];
    try {
      let eth_amount_BN = await WETH.balanceOf(usdc_eth_lp_pair.address);
      let eth_amount = Number(getBalance2(eth_amount_BN, WETH.decimal, 6));
      let usdc_amount_BN = await USDC.balanceOf(usdc_eth_lp_pair.address);
      let usdc_amount = Number(getBalance2(usdc_amount_BN, USDC.decimal, 6));
      return (usdc_amount / eth_amount).toString(); //TODO CHANGE TO 6dp probabably // change back to this
      //return (0.25).toString();
    } catch (err) {
      console.error(`Failed to fetch token price of WETH in dollars: ${err}`);
      return;
    }
  }

    /**
   * Method to calculate the tokenPrice of the deposited asset in a pool/bank
   * If the deposited token is an LP it will find the price of its pieces
   * @param tokenName
   * @param token
   * @returns
   */

     async getDepositTokenPriceInDollars(tokenName: string, token: ERC20) {
      try {
      if (tokenName === "LIQD-WETH-LP") {
        return await this.getLPTokenPrice(token, this.LIQD, true);
      } else {
        return await this.getLPTokenPrice(token, this.lqETH, false);
      }
      } catch (err) {
        console.error(`Failed to fetch token price of ${tokenName} in dollars: ${err}`);
        return "1";
      }
    }

  /**
 * Calculates the price of an LP token
 * Reference https://github.com/DefiDebauchery/discordpricebot/blob/4da3cdb57016df108ad2d0bb0c91cd8dd5f9d834/pricebot/pricebot.py#L150
 * @param lpToken the token under calculation
 * @param token the token pair used as reference
 * @param isYToken sanity check
 * @returns price of the LP token
 */

  async getLPTokenPrice(lpToken: ERC20, token: ERC20, isYToken: boolean): Promise<string> {
    try {
    const totalSupply = getBalance2(await lpToken.totalSupply(), lpToken.decimal, 6);
    const tokenSupply = getBalance2(await token.balanceOf(lpToken.address), token.decimal, 6);
    const stat = isYToken === true ? await this.getYTokenStat() : await this.getXTokenStat();
    const priceOfToken = stat.priceInDollars;
    const tokenInLP = Number(tokenSupply) / Number(totalSupply);
    const tokenPrice = (Number(priceOfToken) * tokenInLP * 2)
      .toString();
    return tokenPrice;
  } catch (err) {
    console.error(`Failed to fetch price of ${lpToken.symbol} in dollars: ${err}`);
    return "1";
  }
  }

  /**
   * Calculates the price of a token
   * @param tokenContract the token under calculation as ERC20
   * @returns price of the token in ETH
   */
  
  async getTokenPrice(tokenContract: ERC20): Promise<BigNumber> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { chainId } = this.config; // must be a chain id type
    const { WETH } = this.config.externalTokens;

    const weth = new CoreToken(chainId, WETH[0], WETH[1]); // address, decimals
    const token = new CoreToken(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
    try {
      const lpAddress = await this.fetchLpHardCodedAddresses(weth, token);
      let ethBalanceInLP = await this.ETH.balanceOf(lpAddress);
      let tokenBalanceInLP = await tokenContract.balanceOf(lpAddress);
      let priceBN = ethBalanceInLP.mul(1000000).div(tokenBalanceInLP)
      return priceBN;
    } catch (err) {
      console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
      return;
    }
  }

   /**
   * Calculates price of y Token in dollars
   * @returns price of the token in dollars
   */

  async getYPriceDollars(): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    try {
      const tokenInEth = await this.getTokenPrice(this.LIQD) // returns price in Eth * 1 000 000
      const priceOfOneEth = await this.getWETHPriceFromPancakeswap();
      const priceOfYTokenInDollars = (Number(utils.formatUnits(tokenInEth, 6)) * Number(priceOfOneEth)).toFixed(6)
      return priceOfYTokenInDollars
    } catch (err) {
      console.error(`Failed to fetch LIQD price in dollars: ${err}`);
      return;

    }
  }

   /**
   * Calculates price of eth in dollars
   * @returns price of eth in dollars
   */

  async getEthPriceDollars(): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    try {
    const priceOfOneEth = await this.getWETHPriceFromPancakeswap();
    return Number(priceOfOneEth).toFixed(6)
  } catch (err) {
    console.error(`Failed to fetch ETH price in dollars: ${err}`);
    return;
  }
  }

  // /**
  //  * Calculates price of wstEth in Eth
  //  * @returns price of eth in dollars
  //  */

  //     async getWstEthInEth(): Promise<string> {
  //       const ready = await this.provider.ready;
  //       if (!ready) return;
  //       try {
  //       const priceOfOneWstEth = await this.getWETHPriceFromPancakeswap();
  //       return Number(priceOfOneEth).toFixed(6)
  //     } catch (err) {
  //       console.error(`Failed to fetch ETH price in dollars: ${err}`);
  //       return;
  //     }
  //     }



  // 0x62bc45B7ea9B854FaccE545a267295D8eB4a58F7

   /**
   * Calculates price of yToken in ETH
   * @returns price of yToken in ETH
   */

  async getYPrice(): Promise<BigNumber> {
    const ready = await this.provider.ready;
    if (!ready) return;
    try {
      return await this.getTokenPrice(this.LIQD)
    } catch (err) {
      console.error(`Failed to fetch ETH price in dollars: ${err}`);
      return;
    }
  }

  //===================================================================
  //
  //
  // General Functions
  //
  //
  //===================================================================

  async getBalance() {
    if (this.myAccount === undefined) return;
    const ready = await this.provider.ready;
    if (!ready) return;
    return await this.provider.getBalance(this.myAccount);
  }

  //===================================================================
  //
  //
  // Homepage and Header Functions
  //
  //
  //===================================================================

  /**
   * Method fetches general stats about Y token.
   * @returns Token Stats for yToken
   * priceInEth
   * priceInDollars
   * TotalSupply
   * CirculatingSupply
   * marketCap
   */

  async getYTokenStat(): Promise<TokenStat> {
    const ready = await this.provider.ready;
    if (!ready) return;
    try {
      // const { yTokenDaoFund, yTokenDevFund, yTokenReserve } = this.contracts;
      const { yTokenReserve } = this.contracts;
      const supply = await this.LIQD.totalSupply();
      // const yTokenDaoFundSupply = await this.LIQD.balanceOf(yTokenDaoFund.address);
      // const yTokenDevFundSupply = await this.LIQD.balanceOf(yTokenDevFund.address);
      const yTokenReserveSupply = await this.LIQD.balanceOf(yTokenReserve.address);
      const yTokenCirculatingSupply = supply
        // .sub(yTokenDaoFundSupply)
        // .sub(yTokenDevFundSupply)
        .sub(yTokenReserveSupply);
      const priceInETH = await this.getTokenPriceFromPancakeswap(this.LIQD);
      const priceOfOneETH = await this.getWETHPriceFromPancakeswap();
      const priceOfYTokenInDollars = (Number(priceInETH) * Number(priceOfOneETH)).toFixed(6);
      const yTokenMarketCap = (Number(yTokenCirculatingSupply.div(BigNumber.from(10).pow(18))) * Number(priceOfYTokenInDollars));

      const { chainId } = this.config; // must be a chain id type
      const { WETH } = this.config.externalTokens;
      const weth = new CoreToken(chainId, WETH[0], WETH[1]); // address, decimals
      const yToken = new CoreToken(chainId, this.LIQD.address, this.LIQD.decimal, this.LIQD.symbol);

      const lpAddress = await this.fetchLpHardCodedAddresses(weth, yToken);
      const ethBalanceInLP = await this.ETH.balanceOf(lpAddress);
      const lpMarketCap = Number(getBalance2(ethBalanceInLP, 18, 6)) * Number(priceOfOneETH) * 2

      return {
        tokenInEth: priceInETH,
        priceInDollars: priceOfYTokenInDollars,
        ethInDollars: Number(priceOfOneETH).toLocaleString("en-US", { maximumFractionDigits: 3, useGrouping: false }),
        circulatingSupply: getBalance2(yTokenCirculatingSupply, this.LIQD.decimal, 0),
        marketCap: yTokenMarketCap,
        lpMarketCap: lpMarketCap,
        farmApr: 0,
      }

    } catch (err) {
      console.error(`Failed to getYTokenStat: ${err}`);
      return;
    }
  }

  async getXTokenInEth(): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    try {
      const priceInETH = await this.getTokenPriceFromPancakeswap(this.lqETH);
      return priceInETH
    } catch (err) {
      console.error(`Failed to getXTokenInEth: ${err}`);
      return;
    }
  }


  /**
   * Method fetches general stats about Y token.
   * @returns TokenStat for lqETH
   * priceInEth
   * priceInDollars
   * TotalSupply
   * CirculatingSupply
   * marketCap
   */

  async getXTokenStat(): Promise<TokenStat> {
    const ready = await this.provider.ready;
    if (!ready) return;
    try {
      const supply = await this.lqETH.totalSupply();
      const priceInETH = await this.getTokenPriceFromPancakeswap(this.lqETH);
      const priceOfOneETH = await this.getWETHPriceFromPancakeswap();
      const priceInDollars = (Number(priceInETH) * Number(priceOfOneETH)).toFixed(6);
      const xTokenMarketCap = (Number(supply.div(BigNumber.from(10).pow(18))) * Number(priceInDollars));

      const { chainId } = this.config; // must be a chain id type
      const { WETH } = this.config.externalTokens;
      const weth = new CoreToken(chainId, WETH[0], WETH[1]); // address, decimals
      const xToken = new CoreToken(chainId, this.lqETH.address, this.lqETH.decimal, this.lqETH.symbol);

      const lpAddress = await this.fetchLpHardCodedAddresses(weth, xToken);
      let ethBalanceInLP = await this.ETH.balanceOf(lpAddress);
      let lpMarketCap = Number(getBalance2(ethBalanceInLP, 18, 6)) * Number(priceOfOneETH) * 2

      return {
        tokenInEth: priceInETH,
        priceInDollars: priceInDollars,
        ethInDollars: Number(priceOfOneETH).toLocaleString("en-US", { maximumFractionDigits: 6, useGrouping: false }),
        circulatingSupply: getBalance2(supply, this.lqETH.decimal, 0),
        marketCap: xTokenMarketCap,
        lpMarketCap: lpMarketCap,
        farmApr: 0,
      };
    } catch (err) {
      console.error(`Failed to getXTokenStat: ${err}`);
      return;
    }
  }

  
  /**
   * Method fetches general info about alternate treasury strats.
   * @returns TokenStat for alt treasury Strats
   * priceInEth
   * priceInDollars
   * TotalSupply
   * CirculatingSupply
   * marketCap
   */

  async getAltTreasuryStrat(): Promise<AltTreasuryStratType> {
    const ready = await this.provider.ready;
    const balWstEthGauge = this.contracts["balancerWstEthGauge"];
    const balVault = this.contracts["balValut"]
    const balWstEthGauge2 = this.contracts["balancerWstEthGauge2"];
    const balWstEthLP = this.contracts["balancerWstEthLP"];
    const treasuryContract = this.contracts["treasury"];

    const xCalGauge = this.contracts["xCalGauge"]
    if (!ready) return;
    try {
     const xCal = await xCalGauge.balanceOf("0xe1cAFf10Dca5332A2163d8b283173F412e7533E1");
     //const xCalBTC = await xCalGauge.balanceOf("0xe1cAFf10Dca5332A2163d8b283173F412e7533E1");
     const wstEthLpBalance = await balVault.getPoolTokens("0xfb5e6d0c1dfed2ba000fbc040ab8df3615ac329c000000000000000000000159")
     const wstEthLpBalanceInGauge = await balWstEthGauge.balanceOf("0x137eda1163CD2233D3dee27E998BF8E069dDdb9a") // balancerStrat contract
     //console.log("wstEthLpBalanceInGauge", getBalance2(wstEthLpBalanceInGauge))
     const totalLpInBalGauge = await balWstEthGauge.totalSupply()
     const percentOfWstEthOwned = getBalance2(wstEthLpBalanceInGauge.mul("1000000000000000000").div(totalLpInBalGauge.toString()));

     // calculate value of BLP
     const ethPriceInDollars = await this.getEthPriceDollars();
     const ethInWstEthBalLP = wstEthLpBalance.balances[1];
     const ethInLp = getBalance2(ethInWstEthBalLP);
     const ethInLpInDollars = Number(ethInLp) * Number(ethPriceInDollars)
     const wstEthInWstEthBalLP = wstEthLpBalance.balances[0];

          // To get price from uniswap V3
          const [immutables, state] = await Promise.all([this.getPoolImmutables(this.wstEthWETH_LP), this.getPoolState(this.wstEthWETH_LP)])
          const wstEth = new Token(3, immutables.token0, 18, 'wstETH', 'Wrapped Staked Ether 2.0')
          const WETH = new Token(3, immutables.token1, 18, 'WETH', 'Wrapped Ether')
    
          const poolExample = new Pool(
            wstEth,
            WETH,
            immutables.fee,
            state.sqrtPriceX96.toString(),
            state.liquidity.toString(),
            state.tick
          )
    
          const token0Price = poolExample.token0Price;
    
        const dollarValueOfAllBLP = Number(getBalance2(wstEthInWstEthBalLP)) * Number(token0Price.toSignificant()) * Number(ethPriceInDollars) + Number(ethInLpInDollars);
        const dollarValueOfBLP = dollarValueOfAllBLP * Number(percentOfWstEthOwned)
        //console.log("dollarValueOfBLP", dollarValueOfBLP)


      //newBalPool
      const wstEthLpBalance2 = await balVault.getPoolTokens("0x36bf227d6bac96e2ab1ebb5492ecec69c691943f000200000000000000000316")
      // console.log("totalLpInBalGauge2", wstEthLpBalance2)
      const wstEthLpBalanceInGauge3 = await balWstEthGauge2.balanceOf("0x137eda1163CD2233D3dee27E998BF8E069dDdb9a") // balancerStrat contract
      const wstEthLpBalanceInLP = await balWstEthLP.balanceOf(treasuryContract.address)
      //console.log("wstEthLpBalanceInLP", getBalance2(wstEthLpBalanceInLP))
      const wstEthLpBalanceInGauge2 = wstEthLpBalanceInLP.add(wstEthLpBalanceInGauge3)
      //console.log("wstEthLpBalanceInGauge2", getBalance2(wstEthLpBalanceInGauge2))

      // removed to change from total lp in vault to total LP
      // const totalLpInBalGauge2 = await balWstEthGauge2.totalSupply()
      const totalLpInBalGauge2 = await balWstEthLP.totalSupply()

      // console.log("totalLpInBalGauge2", getBalance2(totalLpInBalGauge2))
      const percentOfWstEthOwned2 = getBalance2(wstEthLpBalanceInGauge2.mul("1000000000000000000").div(totalLpInBalGauge2.toString()));

      //0x36bf227d6bac96e2ab1ebb5492ecec69c691943f000200000000000000000316

      // calculate value of BLP
      const ethInWstEthBalLP2 = wstEthLpBalance2.balances[1];
      // console.log("ethInWstEthBalLP2", ethInWstEthBalLP2)
      const ethInLp2 = getBalance2(ethInWstEthBalLP2);
      // console.log("ethInLp2", ethInLp2)
      const ethInLpInDollars2 = Number(ethInLp2) * Number(ethPriceInDollars)
      // console.log("ethInLpInDollars2", ethInLpInDollars2)
      const wstEthInWstEthBalLP2 = wstEthLpBalance2.balances[0];
      // console.log("wstEthInWstEthBalLP2", wstEthInWstEthBalLP2)

      const dollarValueOfAllBLP2 = Number(getBalance2(wstEthInWstEthBalLP2)) * Number(token0Price.toSignificant()) * Number(ethPriceInDollars) + Number(ethInLpInDollars2);
      // console.log("dollarValueOfAllBLP2", dollarValueOfAllBLP2)
      const dollarValueOfBLP2 = dollarValueOfAllBLP2 * Number(percentOfWstEthOwned2)
      // console.log("percentOfWstEthOwned2", percentOfWstEthOwned2)

         //console.log("dollarValueOfBLP2", dollarValueOfBLP2)
  

    // LDO token
    // balance of treasury LDO
    const ldoBalanceTreasury = await this.externalTokens["LDO"].balanceOf("0x61fb28d32447ef7F4e85Cf247CB9135b4E9886C2")
    const ldoBalanceStrat = await this.externalTokens["LDO"].balanceOf("0x137eda1163CD2233D3dee27E998BF8E069dDdb9a")
    // balance of treasury BAL
    const balBalanceTreasury = await this.externalTokens["BAL"].balanceOf("0x61fb28d32447ef7F4e85Cf247CB9135b4E9886C2")
    const balBalanceStrat = await this.externalTokens["BAL"].balanceOf("0x137eda1163CD2233D3dee27E998BF8E069dDdb9a")

    const ldoBalance = ldoBalanceTreasury.add(ldoBalanceStrat);
    const balBalance = balBalanceTreasury.add(balBalanceStrat);

    // 0x137eda1163CD2233D3dee27E998BF8E069dDdb9a === balancer strat

    // Doesn't seem to work
    // const ldoClaimable = await balWstEthGauge.claimable_reward("0x137eda1163CD2233D3dee27E998BF8E069dDdb9a", "0x61fb28d32447ef7F4e85Cf247CB9135b4E9886C2")
    // console.log("ldoClaimable", ldoClaimable)
    // const balClaimable = await balWstEthGauge.claimable_reward("0x137eda1163CD2233D3dee27E998BF8E069dDdb9a", "0x61fb28d32447ef7F4e85Cf247CB9135b4E9886C2")
    // console.log("balClaimable", balClaimable)

    const btcInHardware = await this.BTC.balanceOf("0xe1cAFf10Dca5332A2163d8b283173F412e7533E1");
        // console.log("btcInHardware", btcInHardware)
    const priceBtc = await this.CoinGeckoClient.simple.price({
      ids: ["wrapped-bitcoin"],
      vs_currencies: ["usd"]})
    
      // console.log("priceBtc", priceBtc)
    const btcUSD = Number(getBalance2(btcInHardware, 8)) * priceBtc.data["wrapped-bitcoin"].usd
    // console.log("btcUSD", btcUSD)

    const priceLdo = await this.CoinGeckoClient.simple.price({
      ids: ["lido-dao"],
      vs_currencies: ["usd"]})
      
    const ldoUSD = Number(getBalance2(ldoBalance)) * priceLdo.data['lido-dao'].usd

    const priceBal = await this.CoinGeckoClient.simple.price({
      ids: ["balancer"],
      vs_currencies: ["usd"]})
      
    const balUSD = Number(getBalance2(balBalance)) * priceBal.data['balancer'].usd;

    // xCal_lqETHWETH_LP
    // number of tokens in hardware wallet // NEED TO UPDATE TO GET BALANCE FROM FARM IF STAKED
    const xCalXTokenLpInHardware = await this.xCal_lqETHWETH_LP.balanceOf("0xe1cAFf10Dca5332A2163d8b283173F412e7533E1");
    const xCalBTCTokenLpInHardware = await this.xCal_BTClqETH_LP.balanceOf("0xe1cAFf10Dca5332A2163d8b283173F412e7533E1");
    //console.log("xCalBTCTokenLpInHardware", xCalBTCTokenLpInHardware)
    // token0 == WETH
    // how much weth in xCal LP
    const balanceXCalxTokenLP = await this.xCal_lqETHWETH_LP.getReserves();
    const ethReserveXcal = getBalance2(balanceXCalxTokenLP[0]);
    // ETH reserves multiplied by eth price * 2
    const XcalTotalLpInDollars = (Number(ethReserveXcal)) * Number(ethPriceInDollars) * 2;

     // token1 == WETH
    // how much weth in xCal LP
    const balanceXCalBTCLP = await this.xCal_BTClqETH_LP.getReserves();
    //console.log("balanceXCalBTCLP",balanceXCalBTCLP)
    const ethInBTCReserveXcal = getBalance2(balanceXCalBTCLP[1]);
    //console.log("ethInBTCReserveXcal",ethInBTCReserveXcal.toString())
    // ETH reserves multiplied by eth price * 2
    const XcalTotalBTCLpInDollars = (Number(ethInBTCReserveXcal)) * Number(ethPriceInDollars) * 2;
    //console.log("XcalTotalBTCLpInDollars",XcalTotalBTCLpInDollars)


    // To get dollar value of hardware treasury LP, get hardware LP/ total supply LP * Lp total value dollars
    const totalSupplyXcalLp = await this.xCal_lqETHWETH_LP.totalSupply();
    const percentSupplyOfXcalXLP = Number(getBalance2(xCalXTokenLpInHardware.add(xCal))) / Number(getBalance2(totalSupplyXcalLp))
    const hardwareXCalXLpInDollars = percentSupplyOfXcalXLP * XcalTotalLpInDollars

        // To get dollar value of hardware treasury LP, get hardware LP/ total supply LP * Lp total value dollars
        const totalSupplyBTCXcalLp = await this.xCal_BTClqETH_LP.totalSupply();
        //const percentSupplyOfBTCXcalXLP = Number(getBalance2(xCalBTCTokenLpInHardware.add(xCalBTC))) / Number(getBalance2(totalSupplyBTCXcalLp))
        const percentSupplyOfBTCXcalXLP = Number(getBalance2(xCalBTCTokenLpInHardware)) / Number(getBalance2(totalSupplyBTCXcalLp))
        const hardwareXCalBTCLpInDollars = percentSupplyOfBTCXcalXLP * XcalTotalBTCLpInDollars
        //console.log("hardwareXCalBTCLpInDollars", hardwareXCalBTCLpInDollars)

    const wstEthTreasury = await this.wstETH.balanceOf(treasuryContract.address)

    const pricewstEth = await this.CoinGeckoClient.simple.price({
      ids: ["wrapped-steth"],
      vs_currencies: ["usd"]})

      
    const wstEthUSD = Number(getBalance2(wstEthTreasury)) * pricewstEth.data['wrapped-steth'].usd;

    return(
      {
        usdValBLP: (dollarValueOfBLP + dollarValueOfBLP2).toString(),
        usdValBal: balUSD.toString(),
        usdValLdo: ldoUSD.toString(),
        usdValXcalXLPHardwareTreasury: hardwareXCalXLpInDollars.toString(),
        usdValXcalBTCLPHardwareTreasury: hardwareXCalBTCLpInDollars.toString(),
        btcHardware: btcUSD.toString(),
        wstEth: wstEthUSD.toString()
      }
      );
    } catch (err) {
      console.error(`Failed to getAltTreasuryStrat: ${err}`);
      return;
    }
  }

  //===================================================================
  //
  //
  // Mint and Redeem functions
  //
  //
  //===================================================================

  /**
   * Method fetches general stats for mint and redeem pages /// MAYBE REDUNDANT
   * @returns MintData object
   * userEth
   * userXToken
   * userYToken
   * mintFee
   * redFee
   * collateralRatio
   * colBalance
   */

  async getMint(): Promise<MintData> {
    if (this.myAccount === undefined) return;
    const ready = await this.provider.ready;
    if (!ready) return;
    const account = this.myAccount;
    const mintContract = this.contracts["pool"];
    try {
      const info = await mintContract.info();
      const colRatio = info[0];
      const mintFee = info[2]
      const redFee = info[3];
      const colBalance = info[6];
      const userEth = await this.provider.getBalance(account);
      const userXToken = await this.lqETH.balanceOf(account);
      const userYToken = await this.LIQD.balanceOf(account);
      return {
        userEth: getBalance2(userEth, 18, 6), // dont need
        userXToken: getBalance2(userXToken, 18, 6), // dont need
        userYToken: getBalance2(userYToken, 18, 6), // dont neeed
        mintFee: getBalance2(mintFee, 6, 3),
        redFee: getBalance2(redFee, 6, 3),
        collateralRatio: getBalance2(colRatio, 6, 6),
        colBalance: getBalance2(colBalance, 18, 2)
      };
    } catch (err) {
      console.error(`Failed to fetch getMintBN mint data: ${err}`);
      return;
    }
  }

  /**
   * Method fetches general stats for mint and redeem pages
   * @returns MintData object
   * mintFee
   * redFee
   * collateralRatio
   * colBalance
   * realColRatio
   */

  async getMintBN(): Promise<MintDataBN> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const mintContract = this.contracts["pool"];
    const yOracle = this.contracts["UniswapPairOracleY"];
    try {
      const info = await mintContract.info();
      const colRatio = info[0];
      const mintFee = info[1] 
      const redFee = info[2];
      const colBalance = info[5];
      const realColRatio = await mintContract.realCollateralRatio();
      const yUpdate = await yOracle.blockTimestampLast();
      const currentBlock = this.provider.blockNumber
      const currentBlockTime = (await this.provider._getBlock(currentBlock)).timestamp;
      const yPeriod = await yOracle.PERIOD();
      return {
        mintFee: mintFee, // divide by 1,000,000 to get decimal // Mint.js
        redFee: redFee, // getFinanceBalance(redFee, 6, 3), // divide by 1,000,000 to get decimal
        collateralRatio: colRatio, // divide by 1,000,000 // Mint.js
        colBalance: colBalance, // getFinanceBalance(colBalance, 18, 2) // in wei
        realColRatio: realColRatio,
        lastYOracleUpdate: yUpdate,
        currentBlockTime: currentBlockTime,
        yOraclePeriod: yPeriod.toNumber()
      };
    } catch (err) {
      console.error(`Failed to fetch getMintBN mint data: ${err}`);
      return;
    }
  }

   /**
   * Calculates user allowance for lqETH redeeem
   * @returns BigNumber
   * allowance
   */

  async getAllowanceRedeem(): Promise<BigNumber> {
    if (this.myAccount === undefined) return;
    let allowance = await this.lqETH.allowance(this.myAccount, this.contracts["pool"].address);
    return (
      allowance
    )
  };

  //===================================================================
  //
  //
  // Farms functions
  //
  //
  //===================================================================

   /**
   * Method fetches staked balance for each farm/bank on farms page
   * @param poolName
   * @param poolId
   * @param account
   * @returns
   * staked balance on farm
   */

  async stakedBalanceOnBank(poolName: ContractName, poolId: Number, account = this.myAccount): Promise<BigNumber> {
    const pool = this.contracts[poolName];
    try {
      let userInfo = await pool.userInfo(poolId, account);
      let userAmount = await userInfo.amount;
      return userAmount;
    } catch (err) {
      console.error(`Failed to call balanceOf() on pool ${pool.address}: ${err}`);
      return BigNumber.from(0);
    }
  }

   /**
   * Method fetches pending rewards for both banks and number of banks rewards come from
   * @param poolName
   * @param account
   * @returns
   * pending rewards
   * number of pools rewards came from
   */

  async pendingFromBanks(
    poolName: ContractName,
    account = this.myAccount,
  ): Promise<FarmsRewards> {
    const pool = this.contracts[poolName];
    try {
      let banksRewarding: number = 0;
      let pool0: BigNumber = await pool.pendingReward(0, account);
      if (pool0 > BigNumber.from(0)) { banksRewarding++ };
      let pool1: BigNumber = await pool.pendingReward(1, account);
      if (pool1 > BigNumber.from(0)) { banksRewarding++ };
      let both = (pool0).add(pool1);
      return {
        pending: getBalance2(both, this.LIQD.decimal, 4),
        poolsRewards: banksRewarding > 0 ? `(${banksRewarding})` : ""
      }
    } catch (err) {
      //console.error(`Failed to call pendingReward() on pool ${pool.address}: ${err.stack}`);
      return {
        pending: "0",
        poolsRewards: ""
      }
    }
  }

    /**
   * Method fetches pending rewards for a single bank
   * @param bank
   * @param account
   * @returns
   * pending rewards for that bank
   */

  async pendingFromBank(
    bank: Bank,
    account = this.myAccount,
  ): Promise<FarmsRewards> {
    const pool = this.contracts[bank.contract];
    try {
      const pending: BigNumber = await pool.pendingReward(bank.poolId, account);
      return { pending: getBalance2(pending, this.LIQD.decimal, 18) }
    } catch (err) {
      console.error(`Failed to call pendingReward() on pool ${pool.address}: ${err}`);
      return {
        pending: "0",
      }
    }
  }

   /**
   * Method fetches pending rewards for a single bank but in BigNumber
   * @param bank
   * @param account
   * @returns
   * pending rewards for that bank
   */

  async pendingFromBankBN(
    bank: Bank,
    account = this.myAccount,
  ): Promise<FarmsRewardsBN> {
    const chef = this.contracts[bank.contract];
    try {
      return { pending: await chef.pendingReward(bank.poolId, account) }
    } catch (err) {
      //console.error(`Failed to call pendingReward() on pool ${pool.address}: ${err.stack}`);
      return {
        pending: BigNumber.from("0"),
      }
    }
  }

  async getHomePoolAPRsBN(bank: Bank): Promise<HomePoolStatsBN> {
    //if (this.myAccount === undefined) return;
      const depositToken = bank.depositToken;
      const poolContract = this.contracts[bank.contract];
      const depositTokenPrice = await this.getDepositTokenPriceInDollars(bank.depositTokenName, depositToken);
      const stakeInPool = await depositToken.balanceOf(bank.address);
      const stat = await this.getYTokenStat()
      const poolInfo = await poolContract.poolInfo(bank.poolId);
      const totalAlloc = await poolContract.totalAllocPoint();
      const poolAlloc = poolInfo.allocPoint;
      const tokensPerSecond = await poolContract.rewardPerSecond();
      const poolTokensPerSec = tokensPerSecond.mul(poolAlloc).div(totalAlloc);
      const tokensPerHour = poolTokensPerSec.mul(60).mul(60);
      const totalRewardPricePerYear =
        Number(stat.priceInDollars) * Number(getBalance2(tokensPerHour.mul(24).mul(365), 18, 6));
      const totalStakingTokenInPool =
        Number(depositTokenPrice) * Number(getBalance2(stakeInPool, depositToken.decimal, 6));

      const yearlyAPR = (totalRewardPricePerYear / totalStakingTokenInPool);
        return {
          yearlyAPR: yearlyAPR.toFixed(2),
        };
  }

  /**
   * Calculates the TVL, APR and daily APR of a provided pool/bank
   * @param bank
   * @returns PoolStatsBN
   * tokenPerDay
   * userWalletBalance
   * userStaked
   * userStakedInDollars
   * dailyAPR
   * yearlyAPR
   * TVL
   */

  async getPoolAPRsBN(bank: Bank): Promise<PoolStatsBN> {
    //if (this.myAccount === undefined) return;
    try {
      const depositToken = bank.depositToken;
      const poolContract = this.contracts[bank.contract];
      const depositTokenPrice = await this.getDepositTokenPriceInDollars(bank.depositTokenName, depositToken);
      const stakeInPool = await depositToken.balanceOf(bank.address);
      const TVL = Number(depositTokenPrice) * Number(getBalance2(stakeInPool, depositToken.decimal, 6));
      const stat = await this.getYTokenStat()
      const poolInfo = await poolContract.poolInfo(bank.poolId);
      const totalAlloc = await poolContract.totalAllocPoint();
      const poolAlloc = poolInfo.allocPoint;
      const tokensPerSecond = await poolContract.rewardPerSecond();
      const poolTokensPerSec = tokensPerSecond.mul(poolAlloc).div(totalAlloc);
      const tokensPerHour = poolTokensPerSec.mul(60).mul(60);
      const tokenPerDay = getBalance2(tokensPerHour.mul(24), 18, 2);
      const totalRewardPricePerYear =
        Number(stat.priceInDollars) * Number(getBalance2(tokensPerHour.mul(24).mul(365), 18, 6));
      const totalRewardPricePerDay = Number(stat.priceInDollars) * Number(getBalance2(tokensPerHour.mul(24), 18, 6));
      const totalStakingTokenInPool =
        Number(depositTokenPrice) * Number(getBalance2(stakeInPool, depositToken.decimal, 6));

      const dailyAPR = (totalRewardPricePerDay / totalStakingTokenInPool);
      const yearlyAPR = (totalRewardPricePerYear / totalStakingTokenInPool);
      if (this.myAccount === undefined) {
        return {
          tokenPerDay: tokenPerDay,
          userWalletBalance: BigNumber.from(0),
          userStaked: BigNumber.from(0),
          userStakedInDollars: Number(0).toFixed(2),
          dailyAPR: dailyAPR.toFixed(4),
          yearlyAPR: yearlyAPR.toFixed(2),
          TVL: TVL.toFixed(0),
        };
      } else {
        const userStaked = await this.stakedBalanceOnBank(bank.contract, bank.poolId);
        const userStakedInDollars = Number(getBalance2(userStaked, 18, 6)) * Number(depositTokenPrice);
        const userWalletBalance = await depositToken.balanceOf(this.myAccount);
        return {
          tokenPerDay: tokenPerDay,
          userWalletBalance: userWalletBalance,
          userStaked: userStaked,
          userStakedInDollars: userStakedInDollars.toFixed(2),
          dailyAPR: dailyAPR.toFixed(4),
          yearlyAPR: yearlyAPR.toFixed(2),
          TVL: TVL.toFixed(0),
        };
      }
    } catch (err) {
      console.error(`Failed to call getPoolAPRsBN() on pool ${bank.address}: ${err}`);
      return;
    }
  }

  //===================================================================
  //
  //
  // Zap functions
  //
  //
  //===================================================================

  // Shouldnt exist anymore
  async getZapStats(bank: Bank): Promise<ZapData> {
    const ready = await this.provider.ready;
    if (!ready) return;
    if (this.myAccount === undefined) return;

    let tokenPriceinEth;
    let tokenA: ERC20;
    if (bank.symbol0 === "LIQD") {
      tokenA = this.LIQD;
      tokenPriceinEth = await this.getYTokenStat();
    } else if (bank.symbol0 === "lqETH") {
      tokenA = this.lqETH
      tokenPriceinEth = await this.getXTokenStat()
    } else if (bank.symbol0 === "WETH") {
      tokenA = this.ETH
    };

    let tokenB: ERC20;
    if (bank.symbol1 === "LIQD") {
      tokenB = this.LIQD
      tokenPriceinEth = await this.getYTokenStat()
    } else if (bank.symbol1 === "lqETH") {
      tokenB = this.lqETH
      tokenPriceinEth = await this.getXTokenStat()
    } else if (bank.symbol1 === "WETH") {
      tokenB = this.ETH
    };

    const lpAddy = await this.getAddress(tokenA.address, tokenB.address)
    let totalSupply;
    let reserve;
    let token0;
    let token1;

    if (lpAddy.toLowerCase() === this.LIQDWETH_LP.address.toLowerCase()) {
      totalSupply = await this.LIQDWETH_LP.totalSupply();
      reserve = await this.LIQDWETH_LP.getReserves();
      token0 = await this.LIQDWETH_LP.token0();
      token1 = await this.LIQDWETH_LP.token1();
    } else if (lpAddy.toLowerCase() === this.lqETHWETH_LP.address.toLowerCase()) {
      totalSupply = await this.lqETHWETH_LP.totalSupply();
      reserve = await this.lqETHWETH_LP.getReserves();
      token0 = await this.lqETHWETH_LP.token0();
      token1 = await this.lqETHWETH_LP.token1();
    }

    let ethReserve;
    let otherReserve;
    if (this.ETH.address.toLowerCase() === token0.toLowerCase()) {
      ethReserve = reserve[0];
      otherReserve = reserve[1];
    } else if (this.ETH.address.toLowerCase() === token1.toLowerCase()) {
      ethReserve = reserve[1];
      otherReserve = reserve[0]
    };

    const userEth = await this.provider.getBalance(this.myAccount);
    const priceOfOneETH = await this.getWETHPriceFromPancakeswap();

    return {
      userEth: userEth,
      ethReserve: ethReserve,
      otherReserve: otherReserve,
      totalSupplyLp: totalSupply,
      tokenPriceInEth: tokenPriceinEth.tokenInEth,
      tokenPriceInDollars: tokenPriceinEth.priceInDollars,
      ethPriceInDollars: priceOfOneETH
    }
  }

  //===================================================================
  //
  //
  // Staking functions
  //
  //
  //===================================================================
  
  async getYTokenInflationStats(): Promise<PrintRateType> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const supply = await this.LIQD.totalSupply();
    const supplyString = getBalance2(supply,18,4)
    const poolContract = this.contracts["fastChef"];
    const tokensPerSecond = await poolContract.rewardPerSecond();
    const tokensPerHour = tokensPerSecond.mul(60).mul(60);
    const farmTokenPerDay = getBalance2(tokensPerHour.mul(24), 18, 2);

    const bondReserve = this.contracts["bondReserve"];
    const maxIssuancePerEpoch = await bondReserve.maxIssuancePerEpoch();
    // console.log("maxIssuancePerEpoch", maxIssuancePerEpoch)
    const maxIssuancePerEpochNum = Number(utils.formatEther(maxIssuancePerEpoch))
    // console.log("maxIssuancePerEpochNum", maxIssuancePerEpochNum)
    const issueEpochDuration = await bondReserve.issueEpochDuration();
    const issueEpochDurInDays = issueEpochDuration.toNumber() / 60 /60 /24
    const bondsIssuedPerDay = maxIssuancePerEpochNum/issueEpochDurInDays

    const farmBondPrintPerDay = Number(farmTokenPerDay) + bondsIssuedPerDay
    const printPercent = `${getDisplayString((farmBondPrintPerDay * 1.2) / Number(supplyString), 0,2, "percent")}`

    return {
      bondsPerDay: bondsIssuedPerDay.toString(),
      farmPerDay: farmTokenPerDay,
      printPercentPerDay: printPercent,
    }
  }

  async getHomeStakingStats(): Promise<homeStaking> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const stakingContract = this.contracts["staking"];
      // general staking stats for all users
      const totalLockedYToken = await stakingContract.lockedSupply(); // total number of locked tokens (all users)
      const totalSupplyYToken = await stakingContract.totalSupply(); // total supply in staking (all users)
      const stakingYTokenBN = totalSupplyYToken.sub(totalLockedYToken); // total staked supply (all users)
      // rewards data for yToken
      const rewardDataYToken = await stakingContract.rewardData(this.LIQD.address);
      const rewardRateYToken: BigNumber = rewardDataYToken[1];
      const dailyYTokenRewardsBN = rewardRateYToken.mul(60).mul(60).mul(24)
      let totalLockedYTokenChecked0 = totalLockedYToken.eq(0) ? 1 : totalLockedYToken
      const aprYTokenRewardsBN = dailyYTokenRewardsBN.mul(365).mul(BigNumber.from("1000000000000000000")).div(totalLockedYTokenChecked0); // need to divide by 10^18 to get token per token
      const yTokenRewardsApr = Number(getBalance2(aprYTokenRewardsBN, 18, 6))
      // to get APR: (total rewards per year) wei * 10^18 / (number of tokens rewards divided between) wei = output of eth per eth
      const rewardDataEth = await stakingContract.rewardData(this.ETH.address);
      const rewardRateEth = rewardDataEth[1];
      //daily returns Eth
      const dailyEthRewardsBN = rewardRateEth.mul(60).mul(60).mul(24)
      let totalSupplyYTokenChecked0 = totalSupplyYToken.eq(0) ? 1 : totalSupplyYToken;
      const aprEthRewardsBN = dailyEthRewardsBN.mul(365).mul(BigNumber.from("1000000000000000000")).div(totalSupplyYTokenChecked0); // need to divide by 10^18 to get token per token
      const YinEth = await this.getTokenPrice(this.LIQD); // this is a BN percent / 1,000,000 to get a fraction#
      // need to get the inverse of YinEth
      const EthInY = Number((1000000 / YinEth.toNumber()).toFixed(6)) // = 138
      // now we need to multiple eth rewards per token by y per eth to get y value rewards per y token per year
      const ethRewardsApr = Number(getBalance2(aprEthRewardsBN, 18, 6)) * EthInY // which is just equal to APR
      // Check finish date not before current date 
      const unixNow = Date.now()
      const checkedYTokenApr = rewardDataYToken.periodFinish.mul(1000).lte(unixNow) ? 0 : yTokenRewardsApr;
      const checkedEthApr = rewardDataEth.periodFinish.mul(1000).lte(unixNow) ? 0 : ethRewardsApr;
      // lock Apr equal to sum of EthApr and YApr
      const lockApr = checkedEthApr + checkedYTokenApr
      return {
        stakeApr: checkedEthApr,
        lockApr: lockApr,
        lockedYTokenBN: totalLockedYToken,
        stakingYTokenBN: stakingYTokenBN,
      }
  }

    /**
   * Calculates stats for staking page
   * @returns StakingData
   * lockedYTokenBN
      wethProtFeesBN
      stakingYTokenBN
      lockApr
      stakeApr
      vestsTotal
      totalTokensLocked
      vestsEarningsData
      lockedData
      unlockable
      unlockedBalance
      vestingWithdraw
      vestingWithdrawPenalty
      claimableRewardsYToken
      claimableRewardsEth
      totalLockedAndUnlocked
   */

  async getStakingStats(): Promise<StakingData> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const stakingContract = this.contracts["staking"];
    const treasuryContract = this.contracts["treasury"];

      // general staking stats for all users
      const teamLockedForever = await stakingContract.lockedBalances("0x6d306e5f9b0b1aE6e74e6A9357f78d10f21F3128")
      const totalLockedYToken = await stakingContract.lockedSupply(); // total number of locked tokens (all users)
      const totalSupplyYToken = await stakingContract.totalSupply(); // total supply in staking (all users)
      const stakingYTokenBN = totalSupplyYToken.sub(totalLockedYToken); // total staked supply (all users)
      // console.log("stakingYTokenBN", utils.formatEther(stakingYTokenBN)) // staked and vesting
      const wethProtFees = await treasuryContract.balanceOf(this.ETH.address); // ammoung of eth generated through protocol fees stored in the treasury

      // rewards data for yToken
      const rewardDataYToken = await stakingContract.rewardData(this.LIQD.address);
      const rewardRateYToken: BigNumber = rewardDataYToken[1];
      const dailyYTokenRewardsBN = rewardRateYToken.mul(60).mul(60).mul(24)
      let totalLockedYTokenChecked0 = totalLockedYToken.eq(0) ? 1 : totalLockedYToken
      const aprYTokenRewardsBN = dailyYTokenRewardsBN.mul(365).mul(BigNumber.from("1000000000000000000")).div(totalLockedYTokenChecked0); // need to divide by 10^18 to get token per token

      const yTokenRewardsApr = Number(getBalance2(aprYTokenRewardsBN, 18, 6))

      // to get APR: (total rewards per year) wei * 10^18 / (number of tokens rewards divided between) wei = output of eth per eth
      const rewardDataEth = await stakingContract.rewardData(this.ETH.address);
      const rewardRateEth = rewardDataEth[1];
      //daily returns Eth
      const dailyEthRewardsBN = rewardRateEth.mul(60).mul(60).mul(24)
      let totalSupplyYTokenChecked0 = totalSupplyYToken.eq(0) ? 1 : totalSupplyYToken;
      const aprEthRewardsBN = dailyEthRewardsBN.mul(365).mul(BigNumber.from("1000000000000000000")).div(totalSupplyYTokenChecked0); // need to divide by 10^18 to get token per token

      const YinEth = await this.getTokenPrice(this.LIQD); // this is a BN percent / 1,000,000 to get a fraction#

      // need to get the inverse of YinEth
      const EthInY = Number((1000000 / YinEth.toNumber()).toFixed(6)) // = 138

      // now we need to multiple eth rewards per token by y per eth to get y value rewards per y token per year
      const ethRewardsApr = Number(getBalance2(aprEthRewardsBN, 18, 6)) * EthInY // which is just equal to APR

      // Check finish date not before current date 
      const unixNow = Date.now()
      const checkedYTokenApr = rewardDataYToken.periodFinish.mul(1000).lte(unixNow) ? 0 : yTokenRewardsApr;
      const checkedEthApr = rewardDataEth.periodFinish.mul(1000).lte(unixNow) ? 0 : ethRewardsApr;
      // lock Apr equal to sum of EthApr and YApr
      const lockApr = checkedEthApr + checkedYTokenApr

      if (this.myAccount === undefined) return {
        teamLockedForever: teamLockedForever.total,
        lockedYTokenBN: totalLockedYToken,
        wethProtFeesBN: wethProtFees,
        stakingYTokenBN: stakingYTokenBN,
        lockApr: lockApr,
        stakeApr: checkedEthApr,
        vestsTotal: 0,
        totalTokensLocked: 0,
        vestsEarningsData: [],
        lockedData: [],
        unlockable: BigNumber.from(0),
        unlockedBalance: BigNumber.from(0),
        vestingWithdraw: BigNumber.from(0),
        vestingWithdrawPenalty: BigNumber.from(0),
        claimableRewardsYToken: BigNumber.from(0),
        claimableRewardsEth: BigNumber.from(0),
        totalLockedAndUnlocked: BigNumber.from(0),
      }

      const vestsBN = await stakingContract.earnedBalances(this.myAccount);
      const locksBN = await stakingContract.lockedBalances(this.myAccount);
      //withdrawable balance vested and staked
      const unlocked = await stakingContract.unlockedBalance(this.myAccount); // unlocked from vesting
      // early withdraw
      const vestingWithdraw = await stakingContract.withdrawableBalance(this.myAccount);// claimable from vesting with penalty

      // claimable Protocol fees
      const claimableRewards = await stakingContract.claimableRewards(this.myAccount); // claimable protocol fees
      const totalLockedAndUnlocked = locksBN.total

      return {
        teamLockedForever: teamLockedForever.total,
        lockedYTokenBN: totalLockedYToken,
        wethProtFeesBN: wethProtFees,
        stakingYTokenBN: stakingYTokenBN,
        lockApr: lockApr,
        stakeApr: checkedEthApr,
        vestsTotal: Number(getBalance2(vestsBN.total, 18, 6)),
        vestsEarningsData: vestsBN.earningsData,
        totalTokensLocked: Number(getBalance2(locksBN.locked, 18, 6)),
        lockedData: locksBN.lockData,
        unlockable: locksBN.unlockable,
        unlockedBalance: unlocked,
        vestingWithdraw: vestingWithdraw.amount,
        vestingWithdrawPenalty: vestingWithdraw.penaltyAmount,
        claimableRewardsYToken: claimableRewards[0][1],
        claimableRewardsEth: claimableRewards[1][1],
        totalLockedAndUnlocked: totalLockedAndUnlocked,
      };
    // } catch (err) {
    //   console.error(`Failed to call getStakingStats(): ${err}`);
    //   return;
    // }
  }

  /**
   * Calculates treasury stats for staking page and home page (I believe)
   * @returns TreasuryData
   * wethProtFeesInDollars
      trueCollateralRatio
      wethInPool
   */

  async getTreasuryStats(): Promise<TreasuryData> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const poolContract = this.contracts["pool"];
    const treasuryContract = this.contracts["treasury"];
    const bonds = this.contracts["bonds"];
    const bondStrat = this.contracts["bondStrat"];
    const yEth_LP = this.externalTokens['LIQD-WETH-LP']
    const xEth_LP = this.externalTokens['WETH-lqETH-LP']

    try {
      // connect to relavent contracts
      const wethProtFees = await treasuryContract.balanceOf(this.ETH.address); // ammoung of eth generated through protocol fees stored in the treasury
      const priceOfOneETH = await this.getWETHPriceFromPancakeswap();
      const trueCollateralRatio = await poolContract.realCollateralRatio()
      const wethInPool = await this.ETH.balanceOf(poolContract.address)

      const yTokenEthLP = await this.LIQDWETH_LP.balanceOf(treasuryContract.address)
      const yEthLpInDollars = await this.getLPTokenPrice(yEth_LP, this.LIQD, true )
      const xTokenEthLPTreasury = await this.lqETHWETH_LP.balanceOf(treasuryContract.address)
      // NEW for hot wallet
      const xTokenEthLpInHotwallet = await this.lqETHWETH_LP.balanceOf("0xe1cAFf10Dca5332A2163d8b283173F412e7533E1")
      // console.log("xTokenEthLpInHotwallet", xTokenEthLpInHotwallet)
      const xTokenEthLP = xTokenEthLpInHotwallet.add(xTokenEthLPTreasury);
      const xEthLpInDollars = await this.getLPTokenPrice(xEth_LP, this.lqETH, false )

      // lqETH in hardwareWallet
      const lqEthInHardware = await this.lqETH.balanceOf("0xe1cAFf10Dca5332A2163d8b283173F412e7533E1");
      const bondsXToken = await this.lqETH.balanceOf(bonds.address);

      const bondStratXToken = await this.lqETH.balanceOf(bondStrat.address);
      const bondContractsXToken = bondsXToken.add(bondStratXToken).add(lqEthInHardware);
      const bondsXLP = await this.lqETHWETH_LP.balanceOf(bonds.address);
      const bondStratXLP = await this.lqETHWETH_LP.balanceOf(bonds.address);
      const bondContractsXLP = bondsXLP.add(bondStratXLP)
      const bondsWeth = await this.ETH.balanceOf(bonds.address);
      const bondStratWeth = await this.ETH.balanceOf(bonds.address);
      const bondContractsWeth = bondsWeth.add(bondStratWeth)

      const priceInETH = await this.getTokenPriceFromPancakeswap(this.lqETH);
 // TODO add POL data
      return {
        wethProtFeesInDollars: Number(getBalance2(wethProtFees, 18, 6)) * Number(priceOfOneETH),
        trueCollateralRatio: Number(getBalance2(trueCollateralRatio, 6, 6)),
        wethInPool: Number(getBalance2(wethInPool, 18, 6)) * Number(priceOfOneETH),
        xEthLPInDollars: Number(xEthLpInDollars) * Number(getBalance2(xTokenEthLP, 18, 6)),
        yEthLPInDollars: Number(yEthLpInDollars) * Number(getBalance2(yTokenEthLP, 18, 6)),
        bondContractsXToken: Number(getBalance2(bondContractsXToken, 18, 6)) * Number(priceInETH),
        bondContractsXLP: Number(xEthLpInDollars) * Number(getBalance2(bondContractsXLP, 18, 6)),
        bondContractsWeth: Number(getBalance2(bondContractsWeth, 18, 6)) * Number(priceOfOneETH),
      };
    } catch (err) {
      console.error(`Failed to fetch getTreasuryStats treasury data: ${err}`);
      return;
    }
  }


  
async getUnderPegSize(): Promise<BigNumber> {
  const ready = await this.provider.ready;
  if (!ready) return;
  
  const poolContract = this.contracts["pool"];
  const maxArb = await this.ETH.balanceOf(poolContract.address);
  const minArb = "100000000000000"
  const precision = "1000000000000000"

  var sellReserves = await this.ETH.balanceOf(this.lqETHWETH_LP.address);

  var recieveReserves = await this.lqETH.balanceOf(this.lqETHWETH_LP.address);
  var price = (sellReserves.mul("1000000000000000000")).div(recieveReserves);
  var curIn = BigNumber.from(minArb);
  var optimum = BigNumber.from("0");

  while (price.lt("1000000000000000000") && curIn.lt(maxArb)) {
      var expectedOut = this.getAmountOut(curIn, sellReserves, recieveReserves);
      var newSellRes = sellReserves.add(curIn);
      var newRecRes = recieveReserves.sub(expectedOut);
      price = (newSellRes.mul("1000000000000000000")).div(newRecRes);
      optimum = curIn;
      curIn =  curIn.add(precision);
  }
  return optimum;
}


async arberBuybackRedeem(): Promise<arbCalc> {
  const ready = await this.provider.ready;
  if (!ready) return;

  const _wethBorrow = await this.getUnderPegSize();

  const Pool = this.contracts["pool"];
  const poolWethStart = await this.ETH.balanceOf(Pool.address)
  const XSupplyStart = await this.lqETH.totalSupply()

  const _token0address = await this.lqETHWETH_LP.token0();
  const _xPair = await this.lqETHWETH_LP.getReserves()

  let _amountsOut;
  if (_token0address === this.lqETH.address) {
  _amountsOut = this.getAmountOut(_wethBorrow, _xPair[1], _xPair[0], "997");
  } else {
  _amountsOut = this.getAmountOut(_wethBorrow, _xPair[0], _xPair[1], "997");
  }

  const _xTokenIn = _amountsOut;
  const arberCalcRedeem = await Pool.arberCalcRedeem(_xTokenIn)
  const _yTokenOutSpot = arberCalcRedeem._yTokenOutSpot
  var _wethRecievedFromYSwap;

  //wrong
  const _token0address2 = await this.LIQDWETH_LP.token0()
  const _yPair = await this.LIQDWETH_LP.getReserves()

  let _amountsOutY = BigNumber.from(0)
  if (_yTokenOutSpot > 0) {
      if (_token0address2 === this.LIQD.address) {
      _amountsOutY = this.getAmountOut(_wethBorrow, _yPair[0], _yPair[1], "997");
      } else {
      _amountsOutY = this.getAmountOut(_wethBorrow, _yPair[1], _yPair[0], "997");
      }
      _wethRecievedFromYSwap = _amountsOutY;
  } else {
      _wethRecievedFromYSwap = 0;
  }

  const poolWethNew = poolWethStart.sub(_wethBorrow).add(_amountsOutY)
  const XSupplyNew = XSupplyStart.sub(_amountsOut)

  const _poolWETHGain = poolWethNew.sub(XSupplyNew).sub(poolWethStart.sub(XSupplyStart))

  let _tip = BigNumber.from(0);
  const arbTipPercent = BigNumber.from(10000)
  _tip = (_poolWETHGain.mul(arbTipPercent)).div(1000000);

  return ({
      tip: _tip,
      size: _wethBorrow,
      profitable: _poolWETHGain.gt(0)
  })
}

async getOverPegSize(): Promise<BigNumber> {
  const ready = await this.provider.ready;
  if (!ready) return;
  
  const poolContract = this.contracts["pool"];
  const maxArb = await this.ETH.balanceOf(poolContract.address);
  const minArb = "100000000000000"
  const precision = "1000000000000000"

  var sellReserves = await this.lqETH.balanceOf(this.lqETHWETH_LP.address);
  var recieveReserves = await this.ETH.balanceOf(this.lqETHWETH_LP.address);

  var price = (recieveReserves.mul("1000000000000000000")).div(sellReserves);
  var curIn = BigNumber.from(minArb);
  var optimum = BigNumber.from("0");

  while (price.gt("1000000000000000000") && curIn.lt(maxArb)) {
      var expectedOut = this.getAmountOut(curIn, sellReserves, recieveReserves);
      var newSellRes = sellReserves.add(curIn);
      var newRecRes = recieveReserves.sub(expectedOut);
      price = (newRecRes.mul("1000000000000000000")).div(newSellRes);
      optimum = curIn;
      curIn =  curIn.add(precision);
  }
  return optimum;
}


async arberMint(): Promise<arbCalc> {
  const ready = await this.provider.ready;
  if (!ready) return;
  const _xTokenAmount = await this.getOverPegSize();
  const _token0address = await this.lqETHWETH_LP.token0();
  const _xPair = await this.lqETHWETH_LP.getReserves()

  let _amountsOut;
  if (_token0address === this.lqETH.address) {
  _amountsOut = this.getAmountOut(_xTokenAmount, _xPair[0], _xPair[1], "997");
  } else {
  _amountsOut = this.getAmountOut(_xTokenAmount, _xPair[1], _xPair[0], "997");
  }

  const _ethOut = _amountsOut;
  const _wethGained = _ethOut

  let _tip = BigNumber.from(0);
  const arbTipPercent = BigNumber.from(10000)
  _tip = (_wethGained.mul(arbTipPercent)).div(1000000);

  return ({
      tip: _tip,
      size: _xTokenAmount,
      profitable: _wethGained.gt(0)
  })
}


getAmountOut (_amountIn: BigNumber, _reserveIn: BigNumber, _reserveOut: BigNumber, fee = "997") {  
  const _amountInFee = _amountIn.mul(fee);
  const _numerator = _amountInFee.mul(_reserveOut);
  const _denominator = (_reserveIn.mul("1000").add(_amountInFee));

  return _numerator.div(_denominator);
}


  /**
  * Calculates withdrawable balance from mints and redeems
  * @returns UserInfo
  * ethBalance
     xTokenBalance
     yTokenBalance
  */

  async getUserInfo(): Promise<UserInfo> {
    const ready = await this.provider.ready;
    if (!ready) return;
    if (this.myAccount === undefined) return;
    try {
      const poolContract = this.contracts["pool"];
      const userInfo = await poolContract.userInfo(this.myAccount);

      return {
        ethBalance: userInfo.ethBalance,
        xTokenBalance: userInfo.xTokenBalance,
        yTokenBalance: userInfo.yTokenBalance
      };
    } catch (err) {
      console.error(`Failed to fetch getUserInfo treasury data: ${err}`);
      return;
    }
  }


  async getHomeBondData(): Promise<HomeBondData> {
    const ready = await this.provider.ready;
    if (!ready) return;
      // connect to relavent contracts
      const bondsContract = this.contracts["bonds"];
      const bondReserve = this.contracts["bondReserve"];
      const maxIssuancePerEpoch = await bondReserve.maxIssuancePerEpoch();
      // console.log("maxIssuancePerEpoch", maxIssuancePerEpoch)
      const maxIssuancePerEpochNum = Number(utils.formatEther(maxIssuancePerEpoch))
      // console.log("maxIssuancePerEpochNum", maxIssuancePerEpochNum)
      const issueEpochDuration = await bondReserve.issueEpochDuration();
      const issueEpochDurInDays = issueEpochDuration.toNumber() / 60 /60 /24
      const issuedPerDay = maxIssuancePerEpochNum/issueEpochDurInDays


      const bondsAvailable = await bondsContract.bondsAvailable();
      const vestingDurationBlocks = await bondsContract.vestingDurationBlocks();
      const price = await bondsContract.price();
      const yInEth = await this.getYPrice();
      const eth = utils.parseEther("1")
      const yTokenPerEth = eth.mul(eth).div(price);
      const yTokenPerEthInEth = Number(utils.formatEther(yTokenPerEth)) * Number(utils.formatUnits(yInEth, 6))
      return {
        bondsAvailable: bondsAvailable,
        vestingDurationBlocks: vestingDurationBlocks,
        apr: yTokenPerEthInEth,
      };
  }

  /**
  * getBondData
  * @returns UserInfo
  * bondsActive
    bondsAvailable
    minimumBondSize
    vestingDurationBlocks
    owedToUsers
    price
    apr
    yInDollars
    ethInDollars
    claimable
    owedAmount
    estBondEndTime
  */

  async getBondData(): Promise<BondData> {
    const ready = await this.provider.ready;
    if (!ready) return;
    try {
      // connect to relavent contracts
      const bondsContract = this.contracts["bonds"];
      const blockNumber = this.contracts["blockNumber"];
      const blockTime = this.contracts["blockTime"];
      const bondReserve = this.contracts["bondReserve"];
      const maxIssuancePerEpoch = await bondReserve.maxIssuancePerEpoch();
      // console.log("maxIssuancePerEpoch", maxIssuancePerEpoch)
      const maxIssuancePerEpochNum = Number(utils.formatEther(maxIssuancePerEpoch))
      // console.log("maxIssuancePerEpochNum", maxIssuancePerEpochNum)
      const issueEpochDuration = await bondReserve.issueEpochDuration();
      const issueEpochDurInDays = issueEpochDuration.toNumber() / 60 /60 /24
      const issuedPerDay = maxIssuancePerEpochNum/issueEpochDurInDays
      // console.log("issuedPerDay", issuedPerDay)

      const nextEpoch = await bondReserve.currentEpoch();
      const nextEpochUnix = nextEpoch.toNumber() * 1000

      const bondsActive = await bondsContract.bondsActive();
      const bondsAvailable = await bondsContract.bondsAvailable();
      const minimumBondSize = await bondsContract.minimumBondSize();
      const vestingDurationBlocks = await bondsContract.vestingDurationBlocks();

    
      const owedToUsers = await bondsContract.owedToUsers();
      const price = await bondsContract.price();

      const yInEth = await this.getYPrice();
      const eth = utils.parseEther("1")
      const yTokenPerEth = eth.mul(eth).div(price);
      const yTokenPerEthInEth = Number(utils.formatEther(yTokenPerEth)) * Number(utils.formatUnits(yInEth, 6))

      const yInDollars = Number(await this.getYPriceDollars());
      const ethInDollars = Number(await this.getEthPriceDollars());

      if (this.myAccount === undefined) return {
        bondsActive: bondsActive,
        bondsAvailable: bondsAvailable,
        minimumBondSize: minimumBondSize,
        vestingDurationBlocks: vestingDurationBlocks,
        owedToUsers: owedToUsers,
        price: price,
        apr: yTokenPerEthInEth,
        yInDollars: yInDollars,
        ethInDollars: ethInDollars,
        claimable: BigNumber.from(0),
        owedAmount: BigNumber.from(0),
        estBondEndTime: 0,
        issuedPerDay: issuedPerDay,
        nextEpochUnix: nextEpochUnix
      };

      const userInfo = await bondsContract.userInfo(this.myAccount)
      const bondEnds: BigNumber = userInfo.bondEnds;
      const lastClaim: BigNumber = userInfo.lastClaim;
      const owedAmount: BigNumber = userInfo.owedAmount;
      const currentBlockBN = await blockNumber.blockNumber();
      const currentBlock = currentBlockBN.toNumber()

      const lastClaimToEnd = bondEnds.sub(lastClaim)

      let claimable;

      if (currentBlock > bondEnds.toNumber()) {
        claimable = owedAmount;
      } else {
      const blocksSinceLastClaim = BigNumber.from(currentBlock).sub(lastClaim);
      const claimSize = (((blocksSinceLastClaim.mul(1000000)).div(lastClaimToEnd)).mul(owedAmount)).div(1000000);
      claimable = claimSize;
      }

      // function getClaimable (bondEnds: BigNumber, lastClaim: BigNumber) :BigNumber {
      //   if (currentBlock > bondEnds.toNumber()) {
      //     return owedAmount;
      //   } 
      //   const blocksSinceLastClaim = BigNumber.from(currentBlock).sub(lastClaim);
      //   const claimSize = (((blocksSinceLastClaim.mul(1000000)).div(lastClaimToEnd)).mul(owedAmount)).div(1000000);
      //   return claimSize;
      // }

      // const claimable = getClaimable(bondEnds, lastClaim)

      // const currentBlockTime = (await this.provider._getBlock(currentBlock)).timestamp * 1000;
      const currentBlockTimeBN = await blockTime.blockTime();
      const currentBlockTime = currentBlockTimeBN.toNumber() *1000;
      let bondEndTime;

      if (bondEnds.eq(0)) {
        bondEndTime = 0;
      } else {
        let deltaCurrentSubBondEnds = currentBlock - bondEnds.toNumber() // in blocks
        bondEndTime = currentBlockTime - (deltaCurrentSubBondEnds * 13500) // assuming 13.5sec average block time
      }

      return {
        bondsActive: bondsActive,
        bondsAvailable: bondsAvailable,
        minimumBondSize: minimumBondSize,
        vestingDurationBlocks: vestingDurationBlocks,
        owedToUsers: owedToUsers,
        price: price,
        apr: yTokenPerEthInEth,
        yInDollars: yInDollars,
        ethInDollars: ethInDollars,
        claimable: claimable,
        owedAmount: owedAmount,
        estBondEndTime: bondEndTime,
        issuedPerDay: issuedPerDay,
        nextEpochUnix: nextEpochUnix
      };
    } catch (err) {
      console.error(`Failed to fetch getBondData: ${err}`);
      return;
    }
  }

  /**
  * getPresaleData
  * @returns PresaleData
  * redemptionStarted
    privatePresaleActive
    totalAllocated
    totalPurchased
    MAX_PRESALE
    maxWlRemaining
    nWhitelists
    userMaxmum
    allocation
    bought
    owed
  */

  async getPresaleData(): Promise<PresaleData> {
    const ready = await this.provider.ready;
    if (!ready) return;
    try {
      // connect to relavent contracts
      const presaleContract = this.contracts["presale"];

      // general data
      const redemptionStarted = await presaleContract.redemptionStarted();
      const privatePresaleActive: boolean = await presaleContract.privatePresaleActive();
      const totalAllocated: BigNumber = await presaleContract.totalAllocated();
      const totalPurchased: BigNumber = await presaleContract.totalPurchased();
      const MAX_PRESALE: BigNumber = await presaleContract.MAX_PRESALE();
      const maxWlRemaining: BigNumber = await presaleContract.maxWlRemaining();
      const nWhitelists: BigNumber = await presaleContract.nWhitelists();

      if (this.myAccount === undefined) return {
        redemptionStarted: redemptionStarted,
        privatePresaleActive: privatePresaleActive,
        totalAllocated: totalAllocated,
        totalPurchased: totalPurchased,
        MAX_PRESALE: MAX_PRESALE,
        maxWlRemaining: maxWlRemaining,
        nWhitelists: nWhitelists,
        userMaxmum: BigNumber.from(0),
        allocation: BigNumber.from(0),
        bought: BigNumber.from(0),
        owed: BigNumber.from(0),
      };

      const userMaxmum: BigNumber = await presaleContract.getUserMaximum(this.myAccount);
      const userInfo = await presaleContract.UserInfo(this.myAccount);
      return {
        redemptionStarted: redemptionStarted,
        privatePresaleActive: privatePresaleActive,
        totalAllocated: totalAllocated,
        totalPurchased: totalPurchased,
        MAX_PRESALE: MAX_PRESALE,
        maxWlRemaining: maxWlRemaining,
        nWhitelists: nWhitelists,
        userMaxmum: userMaxmum,
        allocation: userInfo.allocation,
        bought: userInfo.bought,
        owed: userInfo.owed,
      };
    } catch (err) {
      console.error(`Failed to fetch getPresaleData: ${err}`);
      return;
    }
  }
  
  async getMintRedeemActive(): Promise<mintRedeemActive> {
    const ready = await this.provider.ready;
    if (!ready) return;
    try {
      // connect to relavent contracts
      const pool = this.contracts["pool"];

      // general data
      const mintPaused = await pool.mintPaused();
      const redeemPaused = await pool.redeemPaused();
    
      return {
        mintPaused: mintPaused,
        redeemPaused: redeemPaused
      };
    } catch (err) {
      console.error(`Failed to fetch getMintRedeemActive: ${err}`);
      return {
        mintPaused: true,
        redeemPaused: true
      }
    }
  }

  ///////////////////////////
  //
  //
  //  WRITE FUNCTIONS
  //
  //
  ///////////////////////////

  /**
 * Mint lqETH.
 * @param ethPaid amount of eth to mint lqETH with.
 * @param minOut minimum amount of lqETH that will be accepted.
 */
  async mint(ethPaid: string, minOut: string): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const { pool } = this.contracts;
    var overrides = {
      value: utils.parseUnits(ethPaid, 18).toString()
    };
    return await pool.mint(utils.parseUnits(minOut, 18).toString(), overrides);
  }

  /**
 * Redeem lqETH.
 * @param xTokenIn amount of xToken to redeem
 * @param minYTokenOut minimum yToken out
 * @param minEthOut minimum eth out 
 */
  async redeem(xTokenIn: string, minYTokenOut: string, minEthOut: string): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const { pool } = this.contracts;
    return await pool.redeem(
      utils.parseUnits(xTokenIn, 18).toString(),
      utils.parseUnits(minYTokenOut, 18).toString(),
      utils.parseUnits(minEthOut, 18).toString()
    );
  }

  /**
* deposit LP in chef.
* @param deposit LP to deposit
* @param bank bank object
*/
  async depositChef(deposit: string, bank: Bank): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const chef = this.contracts[bank.contract];
    return await chef.deposit(
      bank.poolId.toString(),
      utils.parseUnits(deposit, 18).toString(),
      this.myAccount
    );
  }

  /**
* withdraw LP in chef.
* @param deposit LP to withdraw
* @param bank bank object
*/
  async withdrawChef(deposit: string, bank: Bank): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const chef = this.contracts[bank.contract];
    return await chef.withdraw(
      bank.poolId.toString(),
      utils.parseUnits(deposit, 18).toString(),
      this.myAccount
    );
  }

  /**
* harvest rewards for pool in chef.
* @param bank bank object
*/
  async harvestPool(bank: Bank): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const chef = this.contracts[bank.contract];
    return await chef.harvest(
      bank.poolId.toString(),
      this.myAccount
    );
  }
  /**
  * harvest ALL rewards for pool in chef.
  */
  async harvestAllPools(): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const chef = this.contracts["fastChef"];
    return await chef.harvestAllRewards(
      this.myAccount
    );
  }

  /**
  * stake or lock yToken on staking contract.
  * @param amount amount to stake or lock
  * @param lock boolean
  */
  async stake(amount: string, lock: boolean): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const staking = this.contracts["staking"];
    return await staking.stake(
      utils.parseUnits(amount, 18).toString(),
      lock
    );
  }

  /**
  * getReward tokens for locked and staked balances on staking contract.
  */
  async getReward(): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const staking = this.contracts["staking"];
    return await staking.getReward();
  }

  /**
  * withdraw tokens from vested and staked balances on staking contract.
  * @param amount amount of yToken to withdraw
  */
  async withdrawStaking(amount: string): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const staking = this.contracts["staking"];
    return await staking.withdraw(
      utils.parseUnits(amount, 18).toString()
    );
  }

  /**
  * withdrawExpiredLocks withdraw expired locked tokens from staking contract.
  */
  async withdrawExpiredLocks(): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const staking = this.contracts["staking"];
    return await staking.withdrawExpiredLocks();
  }

  /**
* userInfo gets info about pending xToken yToken and Eth from pool contract.
*/
  async collect(): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const pool = this.contracts["pool"];
    return await pool.collect();
  }

  /**
* userInfo gets info about pending xToken yToken and Eth from pool contract.
*/
  async updatePairOracle(tokenName: string): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    let pairOracle;
    if (tokenName === "yToken") {
      pairOracle = this.contracts["UniswapPairOracleY"];
    }
    if (tokenName === "xToken") {
      pairOracle = this.contracts["UniswapPairOracleX"];
    }
    return await pairOracle.update();
  }

  /**
  * buyBonds.
  @param amount Amount of xToken to buy bonds
  @param minRecieve Min Amount of xToken to buy bonds
  */
  async buyBonds(amount: string, minRecieve: string): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const bondsContract = this.contracts["bonds"];
    return await bondsContract.buyBonds(
      utils.parseUnits(amount, 18).toString(),
      utils.parseUnits(minRecieve, 18).toString()
      );
  }

  /**
  * claimTokensFromBonds.
  */
  async claimTokensFromBonds(): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const bondsContract = this.contracts["bonds"];
    return await bondsContract.claimTokens();
  }

  /**
  * claimTokensFromBonds.
  */
  async buyPresaleTokens(amount: string): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const presaleContract = this.contracts["presale"];
    return await presaleContract.buyPresaleTokens(utils.parseUnits(amount, 18).toString());
  }

  /**
  * claimTokensFromBonds.
  */
  async redeemPresaleAllocation(): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const presaleContract = this.contracts["presale"];
    return await presaleContract.redeemAllocation();
  }

  async performUnderPegArb(size: BigNumber): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const arberPub = this.contracts["arberPub"];
    return await arberPub.executeUnderPegArb(size);
  }

  async performOverPegArb(size: BigNumber): Promise<TransactionResponse> {
    if (this.myAccount === undefined) return;
    const arberPub = this.contracts["arberPub"];
    return await arberPub.executeOverPegArb(size.toString());
  }

} 
