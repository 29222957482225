import React, { useContext, useState, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';
import cross from "../../assets/img/cross.svg";
import metamaskLogo from "../../assets/img/metamask-fox.svg";
// import walletConnectLogo from '../../assets/img/wallet-connect.svg';
// import coinBaseLogo from '../../assets/img/coinbase_logo.png';
//import { useWallet } from 'use-wallet';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { SUPPORTED_WALLETS } from '../../constants';
// import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import usePrevious from '../../hooks/usePrevious';

interface WalletConnectModalProps {
    handleClose?: any;
    onClick?: any;
    open?: boolean;
}

const WalletConnectModal: React.FC<WalletConnectModalProps> = ({ handleClose, onClick, open }) => {
  const { color } = useContext(ThemeContext);
  //const { connect } = useWallet();
  const { active, account, connector, activate, error } = useWeb3React();
  // const connectorPrevious = usePrevious(connector);
  const [pendingWallet, setPendingWallet] = useState<AbstractConnector | undefined>();
// console.log("active", active, "connector", connector, "account", account)

  useEffect(() => {
    if (account) {
      handleClose();
    }
  });

  const tryActivation = async (connector: AbstractConnector | undefined) => {
    setPendingWallet(connector); // set wallet for pending view
    //setWalletView(WALLET_VIEWS.PENDING);

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    // if (connector instanceof WalletConnectConnector && connector.walletConnectProvider?.wc?.uri) {
    //   connector.walletConnectProvider = undefined;
    // }

    connector &&
      activate(connector, undefined, true).catch((error) => {
        if (error instanceof UnsupportedChainIdError) {
          activate(connector); // a little janky...can't use setError because the connector isn't set
        } else {
          //setPendingError(true);
        }
      });
  };

  const theme = {
    color: {
      grey: {
        300: color.theme.light.popUps.grey[300],
        500: color.theme.light.popUps.grey[500],
      },
      teal: {
        200: color.theme.light.popUps.teal[200],
        400: color.theme.light.popUps.teal[400],
      },
      primary: color.theme.light.primary,
      secondary: color.theme.light.secondary,
  },
  weight: {
    500: color.theme.light.font.weight[1],
    600: color.theme.light.font.weight[2],
    700: color.theme.light.font.weight[3],
    800: color.theme.light.font.weight[4]
},
borderWidth: color.theme.light.borderWidth.large,
borderRadius: color.theme.light.borderRaidus[2],
}
//console.log("SUPPORTED_WALLETS[METAMASK] ", SUPPORTED_WALLETS["METAMASK"] )


  return (
      <>
      { open ? (
      <ContainerDiv theme={theme} >
          <ContainerInnerDiv theme={theme}>
              <BackgroundDiv onClick={handleClose} theme={theme} />
              <ModalDiv theme={theme}>
                  <CloseButton theme={theme} backgroundImage={cross}  onClick={handleClose} />
                  <TitleDiv theme={theme}>
                      <Title theme={theme}>
                          Select Wallet
                      </Title>
                  </TitleDiv>
                  <WalletTable theme={theme}>
                      <WalletButton theme={theme} onClick={() => {
                        tryActivation(SUPPORTED_WALLETS["METAMASK"].connector) //["METAMASK"] === connector ?
                        //connect('injected'); 
                        }}>
                          <ButtonImg
                                theme={theme}
                              src={metamaskLogo}
                              alt="Metamask logo"
                          />
                          Metamask
                      </WalletButton>
                      {/* <WalletButton theme={theme} onClick={() => { 
                        tryActivation(SUPPORTED_WALLETS["WALLET_CONNECT"].connector)
                        // connect('walletconnect'); 
                        }}>
                          <ButtonImg theme={theme} src={walletConnectLogo} alt="Wallet Connect logo" />
                          Wallet Connect
                      </WalletButton> */}
                      {/* <WalletButton theme={theme} onClick={() => { 
                        tryActivation(SUPPORTED_WALLETS["WALLET_LINK"].connector)
                        // connect('walletlink'); 
                        }}>
                          <ButtonImg theme={theme} src={coinBaseLogo} alt="Coinbase Wallet logo" />
                          Wallet Connect
                      </WalletButton> */}
                  </WalletTable>
              </ModalDiv>
          </ContainerInnerDiv>
      </ContainerDiv>) : (
          <></>
      )
    }
      </>
);
}; 

interface Theme {
    theme: any;
}


const ButtonImg = styled.img<Theme>`
width: 36px;
margin-right: 14px;
:not(:disabled) {
    cursor: pointer;
};
:hover {
    color: ${(props) => props.theme.color.primary};
};
`

const WalletButton = styled.button<Theme>`
    font-family: inherit;
    border: none;
    background: transparent;

    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 18px 0px;
    font-size: 16px;
    font-weight: ${(props) => props.theme.weight[600]};
    color: ${(props) => props.theme.color.primary};
    :hover {
        color: ${(props) => props.theme.color.primary};
        font-weight: ${(props) => props.theme.weight[700]};
        text-decoration: underline;
    };
    :not(:disabled) {
        cursor: pointer;
    };
    :not(:last-child) {
        border-bottom: ${(props) => props.theme.borderWidth} solid ${(props) => props.theme.color.primary};
    };
`

const WalletTable = styled.div<Theme>`
    margin: 0px 20px 20px;
    padding: 0px 20px;
    border-radius: ${(props) => props.theme.borderRadius};
    border: ${(props) => props.theme.borderWidth} solid ${(props) => props.theme.color.primary};
    background-color: ${(props) => props.theme.color.secondary};
    display: block;
`

const Title = styled.div<Theme>`
display: block;
font-size: 20px;
font-weight: ${(props) => props.theme.weight[600]};
color: ${(props) => props.theme.color.primary};
`

const TitleDiv = styled.div<Theme>`
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding: 20px;
    font-size: 20px;
    font-weight: ${(props) => props.theme.weight[600]};
    display: flex;
    -webkit-box-align: center;
    align-items: center;
`

interface CloseButtonProps {
    theme: any;
    fontSize?: string;
    color?: string;
    backgroundImage?: any;
}


const CloseButton = styled.button<CloseButtonProps>`
position: absolute;
z-index: 1;
top: 22px;
right: 24px;
display: flex;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
order: 9;
border: none;
background: transparent;
:not(:disabled) {
    cursor: pointer;
};
::before {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 15px;
    height: 15px;
    background-image: url(${(props) => props.backgroundImage});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    filter: contrast(0.5);
};

`

const ModalDiv = styled.div<Theme>`
    position: relative;
    margin: 0px 5px;
    width: 100%;
    max-width: 500px;
    z-index: 1000;
    border-radius: ${(props) => props.theme.borderRadius};
    background-color: ${(props) => props.theme.color.secondary};
    border: solid ${(props) => props.theme.borderWidth} ${(props) => props.theme.color.primary};
    display: block;
    @media screen and (min-width: 992px) {
        padding: 0px auto;
    };
    ::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        width: 92px;
        height: 88px;
    };
    ::after {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        width: 60px;
        height: 66px;
    }
`

const BackgroundDiv = styled.div<Theme>`
    background-color: rgba(0, 0, 0, 0.533);
    position: absolute;
    inset: 0px;
    z-index: 1;
    display: block;
`

const ContainerInnerDiv = styled.div<Theme>`
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px;
    min-height: 100vh;
    position: relative;
    @media screen and (min-width: 992px) {
        padding: 20px;
    }
`

const ContainerDiv = styled.div<Theme>`
position: fixed;
inset: 0px;
z-index: 1001;
overflow-y: none;
display: block;
`

export default WalletConnectModal;