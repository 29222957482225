export const gradientColor = {
  0: `linear-gradient(93deg, rgba(48, 9, 221,1) 0%, rgba(230, 29, 225,1) 40%, rgba(130, 29, 230,1) 70%, rgba(48, 9, 221,1) 102%)`,
};

export const color = {
  theme: {
    dark: {
      borderWidth: {
        small: "1px",
        medium: "1.5px",
        large: "2px",
        xlarge: "2.5px",
      },
      borderRaidus: {
        0: "0em",
        1: "0.3em",
        2: "0.5em",
      },
      font: {
        weight: {
          0: "normal",
          1: "500",
          2: "600",
          3: "bold",
          4: "bold",
        },
      },
      primary: "rgb(20,23,34)",
      altPrimary: "rgb(20,23,34)",
      primaryTrans: {
        75: "rgba(20,23,34,0.75)"
      },
      secondary: "rgb(255,241,229)",
      alert: "rgb(210, 43, 43)",
      error: 'rgb(255,50,50)',
      inputError: "rgb(255, 84, 84)",
      info: "rgba(48, 9, 221,1)",
      disabled: "rgb(92,120,149)",
      standout: "rgb(241, 60, 242)",
      transparent: {
        10: "rgba(20,23,34,0.1)",
        27: "rgba(20,23,34,0.27)",
        50: "rgba(20,23,34,0.5)"
      },
      white: "rgb(255,255,255)",
      fillBase: "rgb(182, 196, 198)",
      slippageButtons: {
        active: "white"
      },
      headerDropdown: {
        backgroundHover: "rgb(40,43,54)",
      },
      header: {
        backgroundLight: "rgb(30,33,44)",
        text: "rgb(192, 202, 204)",
        priceBackground: "rgb(16, 21, 28)",
        grey: "rgba(255,255,255,0.18)",
      },
      sidebar: {
        priceBackground: "rgba(255, 255, 255, 0.09)",
        before: "rgba(255, 255, 255, 0.4)",
      },
      navList: "rgb(140, 159, 162)",
      navListBackground: "rgb(20,23,34)",
      popUps: {
        grey: {
          300: '#e0e0e0',
          500: '#9e9e9e',
        },
        teal: {
          200: '#64ffda',
          400: "rgb(66, 184, 156)"
        }
      },
      itemButtonWrapper: "rgba(0,0,0,0.065)",
      vestLockWrap: {
        35: "rgba(0,0,0,0.035)",
        75: "rgba(0,0,0,0.075)",
      },
  },
  light: {
    borderWidth: {
      small: "1px",
      medium: "1.5px",
      large: "1px",
      xlarge: "1.5px",
    },
    borderRaidus: {
      0: "0em",
      1: "0.3em",
      2: "0.5em",
    },
    font: {
      weight: {
        0: "normal", //400
        1: "normal", //500
        2: "normal", //600
        3: "500", //700
        4: "600"
      },
    },
    primary: "#c6cdd5",
    altPrimary: "#161b22",
    primaryTrans: {
      75: "rgba(236,242,248,0.75)"
    },
    secondary: "#161b22",
    ghGreen: "#7ce38b",
    ghlghtBlue: "#a2d2fb",
    alert: "rgb(210, 43, 43)",
    error: 'rgb(255,50,50)',
    inputError: "rgb(255, 84, 84)",
    info: "#77bdfb",
    disabled: "#89929b",
    standout: "#77bdfb",
    transparent: {
      0: "rgba(2,2,15,0.25)",
      10: "rgba(239,233,224,0.1)",
      27: "rgba(0,0,0,0.5)",
      50: "rgba(239,233,224,0.5)"
    },
    white: "white",
    fillBase: "#c6cdd5",
    slippageButtons: {
      active:"#21262d"
    },
    headerDropdown: {
      backgroundHover: "#21262d",
    },
    header: {
      backgroundLight: "rgb(30,33,44)",
      text: "#c6cdd5",
      priceBackground: "#161b22",
      grey: "rgba(0,0,0,0.18)",
    },
    sidebar: {
      priceBackground: "rgba(0, 0, 0, 0.09)",
      before: "rgba(0, 0, 0, 0.4)",
    },
    standout2 : {
      0: "rgb(241, 60, 242)"
    },
    navList: "#c6cdd5",
    navListBackground: "#161b22",
    popUps: {
      grey: {
        300: 'rgb(31,31,31)',
        500: 'rgb(97,97,97)',
      },
      teal: {
        200: '#64ffda',
        400: "rgb(66, 184, 156)",
      },
    },
    itemButtonWrapper: "rgba(255,255,255,0.065)",
    vestLockWrap: {
      35: "rgba(255,255,255,0.045)",
      75: "rgba(255,255,255,0.075)",
    },
  },
},
  background: {
    0: "rgb(20,23,34)",
    1: "rgb(20,57,94)",
    2: "rgb(25,67,109)",
    3: "rgb(19,74,130)",
  },
  header:{
    background: "rgb(20,23,34)",
    text: "rgb(192, 202, 204)",
    dropdown: {
      background: "rgba(20,23,34,1)",
      menuBackground: "rgb(15,21,28)",
    },
  },
  coinName: "rgb(160,231,255)",
  standout: {
    0: "rgb(241, 60, 242)",
    1: "rgb(64, 255, 90)",
    2: "rgb(224, 28, 225)",
    3: "rgb(14, 207, 85)",
  },
  error: {
    0: 'rgb(255,50,50)',
  },
  white: "rgb(255,255,255)",
  offWhite: "rgb(229,229,229)",
  tranWhite: {
    10: "rgb(255,255,255,0.1)",
    16: "rgb(255,255,255,0.16)",
    20: "rgb(255,255,255,0.2)",
    30: "rgb(255,255,255,0.3)",
    40: "rgb(255,255,255,0.4)",
    50: "rgb(255,255,255,0.5)"
  },
  tranBlack: {
    10: "rgb(0,0,0,0.1)",
    20: "rgb(0,0,0,0.2)",
    30: "rgb(0,0,0,0.3)",
    40: "rgb(0,0,0,0.4)",
    50: "rgb(0,0,0,0.5)",
  },
  grey: {
    0: "rgb(169,169,169)",
    1: "rgb(203, 203, 203)",
    100: '#f5f5f5',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',

  },
  border: {
    0: "rgb(39,92,138)",
  },
  plusDiv: "rgb(15, 64, 112)",
  modalBackground: "rgba(0, 0, 0, 0.533)",
  fillBase: "rgb(182, 196, 198)",
  input: {
    warning: "rgb(255, 152, 92)",
    error: "rgb(255, 84, 84)",
  },
  footer: "rgb(160,150,150)",
  zap: {
    color: "rgb(221, 197, 21)",
    border: "rgb(255, 245, 58)",
  },




}
