import { useEffect, useState } from 'react';
import useLiquidFinance from './useLiquidFinance';
import { mintRedeemActive } from '../liquid-finance/types';
import useRefresh from './useRefresh';

const useMintRedeemBoolean = () => {
  const [stat, setStat] = useState<mintRedeemActive>({mintPaused: true, redeemPaused: true});
  const { fastRefresh } = useRefresh();
  const liquidFinance = useLiquidFinance();

  useEffect(() => {
    async function fetchMintRedeemBoolean(){
      if (!!liquidFinance) {
      try {
        setStat(await liquidFinance.getMintRedeemActive());
      }
      catch(err){
        console.error(`getMintRedeemActive(): failure ${err}`)
      }
    } else return }
    fetchMintRedeemBoolean();
  }, [setStat, liquidFinance, fastRefresh]);

  return stat;
};

export default useMintRedeemBoolean;
