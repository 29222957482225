//Your theme for the new stuff using material UI has been copied here so it doesn't conflict
import { createTheme } from '@mui/material/styles';

const muiTheme = createTheme({
  palette: {
      text: {
        primary: 'rgb(255,255,255)',
        secondary: 'rgb(255,255,255)',
    },
      background: {
        default: '#121212',
        paper: "rgba(21,62,103,0.85)",
      },
      primary: {
        light: '#757ce8',
        main: "rgba(230, 29, 225,0)",
        dark: 'rgb(255,255,255)',
        contrastText: 'rgb(255,255,255)',
      },
      secondary: {
        light: '#ff7961',
        main: 'rgba(230, 29, 225,1)',
        dark: '#ba000d',
        contrastText: 'rgb(255,255,255)',
      },
      action: {
        disabledBackground: '#0005',
        active: '#000',
        hover: '#000',
      },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: ['Chillax', 'Roboto','Helvetica', 'sans-serif'].join(','),
    fontSize: 14,
    fontWeightLight: "300px",
    fontWeightRegular: "400px",
    fontWeightMedium: "500px",
    fontWeightBold: "700px",
    color: 'rgb(255,255,255)',
    h1: {
      fontWeight: 300,
      fontSize: "6rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontWeight: "300px",
      fontSize: "3.75rem",
      lineHeight: 1.8, //
      letterSpacing: "-0.00833em",
    },
    h3: {
      fontWeight: "400px",
      fontSize: "3rem",
      lineHeight: 1.167,
      letterSpacing: "0em",
    },
    h4: {
      fontWeight: "400px",
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
    },
    h5: {
      fontWeight: "400px",
      fontSize: "1.4rem",
      lineHeight: 1.234,
      letterSpacing: "0.0em",
    },
    h6: {
      fontWeight: "500px",
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
    },
  },
});

export default muiTheme;
