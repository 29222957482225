import React from 'react';
import styled from 'styled-components';

interface DivProps {
    children?: React.ReactNode;
}

const Content: React.FC<DivProps> = ({ children }) => <Div> {children} </Div>;

const Div = styled.div`
    padding: 35px 15px 20px;
    min-height: calc((100vh - 80px) - 50px);
    max-width: 1070px;
    margin: auto;
    display: block;
    @media screen and (min-width: 992px) {
        padding: 55px 20px 20px;
    };
    @media screen and (min-width: 1450px) {
        padding: 30px 20px 20px;
    }
`
export default Content;