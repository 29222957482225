export const getDisplayString = (
  number: number,
  minFractionDigits = 0,
  maxFractionDigits = 2,
  style: "percent" | "dollar"
) => {
    let string;
    if (style === "percent") {
        string = number.toLocaleString("en-US", 
            { style: "percent", minimumFractionDigits: minFractionDigits, maximumFractionDigits: maxFractionDigits, useGrouping: true }
            );
    } else { 
        string = number.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: minFractionDigits, maximumFractionDigits: maxFractionDigits, useGrouping: true })
    }
    return string;
};
