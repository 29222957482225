import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

interface props {
    text: string;
    href?: string;
    leftMarginAuto?: boolean;
    target?: string;
}

const CoinLinkButton: React.FC<props> = ({ text, href, leftMarginAuto, target }) => {
    const { color, font } = useContext(ThemeContext);

    const targetChecked = !target ? "_blank" : target;

    const theme = {
        light: {
            primary: color.theme.light.primary,
            secondary: color.theme.light.secondary,
        },
        font: font,
        fontWeight: {
            500: color.theme.light.font.weight[1],
            600: color.theme.light.font.weight[2],
            700: color.theme.light.font.weight[3]
        },
        borderWidth: color.theme.light.borderWidth.large,
        leftMargin: !!leftMarginAuto? "auto" : "0px" 
    }

    return (
            <StyledLink theme={theme} target={targetChecked} href={href} rel="noreferrer" >
                <StyledDiv theme={theme}>
                    <GhostDiv theme={theme}>
                        {text}
                    </GhostDiv>
                    <TextDiv theme={theme}>
                        {text}
                    </TextDiv>
                </StyledDiv>
            </StyledLink>
    )
};

interface ThemeProps {
    theme: any;
}

const StyledDiv = styled.div<ThemeProps>`
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    text-decoration: none;
    outline: none;
    font-size: ${(props) => props.theme.font.size.normal};
`;

const TextDiv = styled.div<ThemeProps>`
    text-decoration: none;
    outline: none;
    display: block;
    opacity: 1;
    color: ${(props) => props.theme.light.secondary};
    webkit-transition: all 400ms ease;
    transition: all 400ms ease;
`;

const GhostDiv = styled.div<ThemeProps>`
    position: absolute;
    top: -80%;
    opacity: 0;
    color: ${(props) => props.theme.light.primary};
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
`;

const StyledLink = styled.a<ThemeProps>`
    text-decoration: none;
    padding: 0px 18px;
    cursor: pointer;
    z-index: 1;
    background-color: ${(props) => props.theme.light.primary};
    -webkit-box-align: center;
    align-items: center;
    font-size: ${(props) => props.theme.font.size.normal};
    font-weight: 600;
    margin-left: ${(props) => props.theme.leftMargin};
    position: relative;
    border-radius: 0.3em;
    box-shadow: inset 0 0 0 ${(props) => props.theme.borderWidth} ${(props) => props.theme.light.primary};
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    height: 42px;
    transition: all 500ms cubic-bezier(.19, 1, .22, 1);
    font-family: inherit;
    :hover {
        background-color: transparent;
    };
    :hover ${TextDiv} {
        opacity: 0;
        transform: translateY(80%);
    };
    :hover ${GhostDiv} {
        opacity: 1;
        transform: translateY(80%);
    }
`;

export default CoinLinkButton;
