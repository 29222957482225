import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
//import { useWallet } from 'use-wallet';
import truncateEthAddress from "../../../utils/truncateEthAddress";
// import walletModalWeb from "../../../assets/img/walletModalWeb.png";
// import modalSpider from "../../../assets/img/modalSpider.png";
import cross from "../../../assets/img/cross.svg";
// import SolidButton from "../../Buttons/SolidButton";
import {useAllTransactions, useClearAllTransactions} from "../../../state/transactions/hooks";
import config from "../../../config";
import { useWeb3React} from '@web3-react/core';
interface ModalProps {
  children?: React.ReactNode;
  handleClose?: any;
  open?: boolean;
}

const AccountModal: React.FC<ModalProps> = ({ children, handleClose, open }) => {
  const { color } = useContext(ThemeContext);
  const { account } = useWeb3React();
  //const wallet = useWallet();
  const allTransactions = useAllTransactions()
  const { clearAllTransactions } = useClearAllTransactions();

  const transactionArray = [];
  for (let x in allTransactions) {
    if (!!allTransactions[x].receipt) {
      transactionArray.push(allTransactions[x])
    }
  }

  function getLang() {
    if (navigator.language !== undefined) 
      return "en-US"
    return navigator.language;
  }

  const theme = {
    backgroundColor: color.theme.light.secondary,
    primary: color.theme.light.primary,
    secondary: color.theme.light.secondary,
    background: "transparent",
    borderRadius: color.theme.light.borderRaidus[2],
    borderColor: color.theme.light.primary,
    color: color.theme.light.primary,
    borderWidth: color.theme.light.borderWidth.large,
    
    font: {
        size: {
            12: "12px",
            13: "13px",
            14: "14px",
            16: "16px",
            18: "18px",
            20: "20px",
        },
        weight: {
            400: color.theme.light.font.weight[0],
            500: color.theme.light.font.weight[1],
            600: color.theme.light.font.weight[2],
            700: color.theme.light.font.weight[3],
            800: color.theme.light.font.weight[4],
        }
    }
}
type dateOptions = {
  weekday?: "long" | "short" | "narrow";
  year: 'numeric' | '2-digit';
  month: "long" | "short" | "narrow" | "numeric" | "2-digit";
  day: 'numeric' | '2-digit';
  hour: 'numeric' | '2-digit';
  minute: 'numeric' | '2-digit';
  // timeZoneName: "long" | "short" | "shortOffset" | "longOffset" | "shortGeneric" | "longGeneric";
  hourCycle: "h11" | "h12" | "h23" | "h24";
}

const options : dateOptions = {
  // weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: "2-digit",
  minute: "2-digit",
  // timeZoneName: "shortOffset",
  hourCycle: "h24"
}

var date = new Date();
var localOffset = date.getTimezoneOffset() * 60000;


  return (
    <>
    {open ? (
    <Page>
      <Container  theme={theme}>
        <BlurDiv onClick={handleClose}/>
        <ModalDiv theme={theme} >
          <CloseButton onClick={handleClose} />
          <Title theme={theme} >
            {/* <HandImg src={hand} /> */}
            {!account ? (
              <TitleContent  theme={theme}>
              "Not Connected"
              <TitleAddress>
                0xLoG...1n
              </TitleAddress>
            </TitleContent>
            ) : (
            <TitleContent  theme={theme}>
              "Connected"
              <TitleAddress  theme={theme}>
                {truncateEthAddress(account, true)}
              </TitleAddress>
            </TitleContent>)
            } 
          </Title>
          {/* <ButtonsDiv>

            <SolidButton size={"sm"} text={"VIEW ON EXPLORER"} href={`https://ftmscan.com/address/${wallet.account}`} 
            />
            <SolidButton size={"sm"} text={"DISCONNECT"} onClick={() => wallet.reset()} 
            />
            
          </ButtonsDiv> */}
          <DottedLine theme={theme} />
          <TransactionContainer theme={theme}>
            <div>
              <TransactionTitle theme={theme} >
                History
                </TransactionTitle>
            
                    <StyledCard theme={theme} >
                      <Box theme={theme} >

                        {transactionArray.length === 0 ?
                          <React.Fragment>
                            <Item2 theme={theme} >
                              No Transactions
                            </Item2>
                          </React.Fragment> :
                          transactionArray.reverse()
                          // .slice(0,5)
                          .map((item, index) =>
                            <React.Fragment key={index} >
                              <Item theme={theme} >
                                <div style={{display: "flex"}}>
                                  <ItemSpan theme={theme} >
                                    {item.summary}
                                  </ItemSpan>
                                  </div>
                                  <div style={{display: "flex"}}>
                                  <ItemSpanDate theme={theme} >
                                  <span style={{fontWeight: theme.font.weight[600]}}> {'Date: '} </span> &nbsp; {new Intl.DateTimeFormat(getLang(), options).format(new Date(item.confirmedTime  - localOffset))}
                                  </ItemSpanDate>
                                </div>
                                <div style={{display: "flex"}}>
                                <ItemSpan2 theme={theme} target="_blank" href={`${config.blockExplorerUrl}/tx/${item.hash}`}>
                                  <span style={{fontWeight: theme.font.weight[600]}}> {'Hash: '} </span> &nbsp; {item.hash.slice(0, 8) + '...' + item.hash.slice(58, 65)}
                                </ItemSpan2>
                                </div>
                              </Item>
                            </React.Fragment>
                          )
                        }
                      </Box>
                    </StyledCard >

              <ClearButton theme={theme} disabled={transactionArray.length === 0} onClick={() => clearAllTransactions()}>
                Clear
              </ClearButton>

            </div>


          </TransactionContainer>
        </ModalDiv>
      </Container>
    </Page> ) : (<></>)
    }
    </>
  );
};

interface GenProps {
  theme?: any;
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  borderColor?: string;
  backgroundColor?: string;
}


const DottedLine = styled.div<GenProps>`
    display: block;
    margin: 10px 20px 20px;
    border-bottom: ${(props) => props.theme.borderWidth} solid ${(props) => props.theme.color};
`;

const StyledCard = styled.div<GenProps>`
  display: block;
  padding: 15px 20px;
  border: none;
  margin-top: 15px;
  font-size:  ${(props) => props.theme.font.size[14]};
  background-color: ${(props) => props.theme.background};
  @media screen and (min-width: 992px) {
      margin-top: 0px;
  }
`;

const ItemSpanDate = styled.div<GenProps>`
  display: flex;
  margin-left: 8px;
  flex-wrap: wrap;
  font-size:  ${(props) => props.theme.font.size[14]};
  font-weight: ${(props) => props.theme.font.weight[500]};
  color: ${(props) => props.theme.color};
`;


const ItemSpan2 = styled.a<GenProps>`
  color: ${(props) => props.theme.color};
  font-family: inherit;
  text-decoration: none;
  flex-wrap: wrap;
  margin-left: 8px;
  padding-right: 4px;
  font-size:  ${(props) => props.theme.font.size[14]};
  font-weight: ${(props) => props.theme.font.weight[500]};
  :hover {
    text-decoration: underline;
    font-weight: ${(props) => props.theme.font.weight[700]};
  }
`;

const ItemSpan = styled.div<GenProps>`
  display: flex;
  flex-wrap: wrap;
  margin-left: 8px;
  padding-right: 4px;
  font-size:  ${(props) => props.theme.font.size[14]};
  font-weight: ${(props) => props.theme.font.weight[600]};
`;

const Item2 = styled.div<GenProps>`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: ${(props) => props.theme.font.size[16]};
  font-weight: ${(props) => props.theme.font.weight[600]};
  color: ${(props) => props.theme.color};
  padding: 15px 0px;
  text-align: center;
  justify-content: center;
  :not(:last-child) {
      border-bottom: ${(props) => props.theme.borderWidth} solid ${(props) => props.theme.borderColor};
  };
`;

const Item = styled.div<GenProps>`
  display: block;
  -webkit-box-align: center;
  align-items: center;
  font-size: ${(props) => props.theme.font.size[16]};
  font-weight: ${(props) => props.theme.font.weight[600]};
  color: ${(props) => props.theme.color};
  padding: 15px 0px;
  :not(:last-child) {
      border-bottom: ${(props) => props.theme.borderWidth} solid ${(props) => props.theme.borderColor};
  };
`;

const Box = styled.div<GenProps>`
  display: block;
  padding: 3px 18px;
  border-radius: ${(props) => props.theme.borderRadius};
  border: ${(props) => props.theme.borderWidth} solid ${(props) => props.theme.borderColor};
  background-color: ${(props) => props.theme.background};
  overflow-x:hidden;
  overflow-y: scroll;
  max-height: 400px;
`

// const ButtonsDiv = styled.div`
//   display: flex;
//   -webkit-box-align: center;
//   align-items: center;
//   -webkit-box-pack: center;
//   justify-content: center;
//   gap: 20px;
//   padding: 0px 20px 18px;
// `;

const TitleAddress = styled.div<GenProps>`
font-size: 20px;
font-weight: ${(props) => props.theme.font.weight[600]};
color: ${(props) => props.theme.primary};
margin: 0px;
`;

const TitleContent = styled.div<GenProps>`
  font-size: 14px;
  font-weight: ${(props) => props.theme.font.weight[600]};
  color: ${(props) => props.theme.primary};
  display: block;
`;

const Title = styled.div<GenProps>`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding: 20px 20px 10px;
    font-size: 22px;
    font-weight: ${(props) => props.theme.font.weight[700]};
`;

const CloseButton = styled.button`
font-family: inherit;
    border: none;
    background: transparent;
    position: absolute;
    z-index: 1;
    top: 22px;
    right: 24px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    order: 9;
    :not(:disabled) {
        cursor: pointer;
    };
    ::before {
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0px;
      width: 15px;
      height: 15px;
      background-image: url(${cross});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      filter: contrast(0.5);
  };
    :hover::before {
        filter: none;
    };
`

const ModalDiv = styled.div<GenProps>`
    display: block;
    position: relative;
    margin: 0px 5px;
    width: 100%;
    max-width: 500px;
    z-index: 1000;
    border-radius: ${(props) => props.theme.borderRadius};
    background-color: ${(props) => props.theme.secondary};
    @media screen and (min-width: 992px) {
      margin: 0px auto;
    };
`;

const BlurDiv = styled.div<GenProps>`
  background-color: rgba(0, 0, 0, 0.533);
  position: absolute;
  inset: 0px;
  z-index: 1;
  display: block;
`;

const Container = styled.div<GenProps>`
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px;
    min-height: 100vh;
    border-radius: ${(props) => props.theme.borderRadius};
    position: relative;
    @media screen and (min-width: 992px) {
      padding: 20px;
    }
`;

const Page = styled.div`
  position: fixed;
  inset: 0px;
  z-index: 1001;
  overflow-y: auto;
  display: block;
`;

const ClearButton = styled.button<GenProps>`
    border: none;
    color: ${(props) => props.theme.primary};
    background: none;
    font-size: 14px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 20px 10px;
    font-weight: ${(props) => props.theme.font.weight[600]};
    font-family: inherit;
    text-decoration: none;
    :not(:disabled) {
      cursor: pointer;
      :hover {
        color: rgb(255,84,84);
        text-decoration: underline;
      };
    };
`

const TransactionTitle = styled.div<GenProps>`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-bottom: 15px;
    padding: 0px 20px;
    font-size: 16px;
    font-weight: ${(props) => props.theme.font.weight[700]};
    color: ${(props) => props.theme.primary};
    text-transform: uppercase;
`

const TransactionContainer = styled.div<GenProps>`
    border-radius: ${(props) => props.theme.borderRadius};
    background-color: ${(props) => props.theme.backgroundColor};
    display: block;
`

export default AccountModal;
