import { useEffect, useState } from 'react';
import useLiquidFinance from './useLiquidFinance';
import useRefresh from './useRefresh';

const useEthPriceDollars = () => {
  const [ethPrice, setEthPrice] = useState("0");
  const liquidFinance = useLiquidFinance();
  const { slowRefresh } = useRefresh();
  
  // const fetchPrice = useCallback(async () => {
  //   setEthPrice(await liquidFinance.getEthPriceDollars());
  // }, [liquidFinance]);

  // useEffect(() => {
  //       fetchPrice().catch((err) => console.error(`useEthPriceDollars(): Failed to fetch price: ${err.stack}`));
  //     let refreshInterval = setInterval(fetchPrice, config.refreshInterval);
  //     return () => clearInterval(refreshInterval);
  // }, [fetchPrice, liquidFinance]);

  useEffect(() => {
    async function fetch(){
      if (!!liquidFinance) {
      try {
        setEthPrice(await liquidFinance.getEthPriceDollars());
      }
      catch(err){
        console.error(`getEthPriceDollars(): failure ${err}`)
      }
    } else return }
    fetch();
  }, [setEthPrice, liquidFinance, slowRefresh]);

  return ethPrice;
};

export default useEthPriceDollars;
