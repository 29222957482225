import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import useMintRedeemBoolean from "../../../../hooks/useMintRedeemBoolean";

interface Props {
    onClick: () => void;
    children: React.ReactNode;
}

const NavList: React.FC<Props> = ({ onClick, children }) => {
    const { color } = useContext(ThemeContext);
    const path = window.location.href; 
    const mintRedeemBool = useMintRedeemBoolean();


    const theme = {
        image: "",
        imageBefore: "",
        colorActive: color.theme.light.white,
        color: color.theme.light.navList,
    }

  return (
      <ButtonList>
          <ButtonListItem>
              <HeaderButton href="#/" theme={theme} onClick={onClick} active={path.endsWith("/") === true} >
                  Dashboard
              </HeaderButton>
          </ButtonListItem>
          {mintRedeemBool.mintPaused ?
          <></>
          : <ButtonListItem>
          <HeaderButton href="#/Mint" theme={theme} onClick={onClick} active={path.includes("Mint") === true}>
              Mint
          </HeaderButton>
      </ButtonListItem>
            }
        {mintRedeemBool.redeemPaused ?
        <></> : 
          <ButtonListItem>
              <HeaderButton href="#/Redeem" theme={theme} onClick={onClick} active={path.includes("Redeem") === true}>
                  Redeem
              </HeaderButton>
          </ButtonListItem>
}
          <ButtonListItem>
              <HeaderButton href="#/Farms" theme={theme} onClick={onClick} active={path.includes("/Farms") === true}>
                  Farms
              </HeaderButton>
          </ButtonListItem>
          <ButtonListItem>
              <HeaderButton href="#/Staking" theme={theme} onClick={onClick} active={path.includes("Staking") === true}>
                  Staking
              </HeaderButton>
          </ButtonListItem>
          <ButtonListItem>
              <HeaderButton href="#/Bonds" theme={theme} onClick={onClick} active={path.includes("Bonds") === true}>
                  Bonds
              </HeaderButton>
          </ButtonListItem>
          <ButtonListItem>
              <HeaderButton href="#/Arbitrage" theme={theme} onClick={onClick} active={path.includes("Arbitrage") === true}>
                  Arbitrage
              </HeaderButton>
          </ButtonListItem>
          {/* <ButtonListItem>
              <HeaderButton href="#/Whitelist" theme={theme} onClick={onClick} active={path.includes("Whitelist") === true}>
                  Whitelist
              </HeaderButton>
          </ButtonListItem> */}
          {children}
      </ButtonList>
  )
// return (
//     <ButtonList>
//         <ButtonListItem>
//             <HeaderButton href="/" theme={theme} onClick={onClick} active={path.endsWith("/") === true} >
//                 Whitelist
//             </HeaderButton>
//         </ButtonListItem>
//     </ButtonList>
// )
};

interface HeaderButtonProps {
    theme: any;
    active?: boolean;
}

const HeaderButton = styled.a<HeaderButtonProps>`
    position: relative;
    cursor: pointer;
    font-size: 16px;
    font-weight:  ${(props) => props.active ? "600" : "500"};
    color: ${(props) => props.active ? props.theme.colorActive : props.theme.color};
    display: flex;
    text-decoration: ${(props) => props.active ? "underline" : "none"};
    @media screen and (min-width: 1050px) {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        height: 80px;
        font-weight:  ${(props) => props.active ? "500" : "400"};
        margin: 0px 20px;
        font-size: 18px;
        ::after {
            display: none;
        };
        ::before {
            content: ${(props) => props.active ? "" : "none"};
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0px);
            top: 95%;
            width: 27px;
            height: 55px;
            background-image: url(${(props) => props.theme.imageBefore});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center bottom;
        };
    };
    @media screen and (min-width: 1600px) {
        margin: 0px 25px;
    };
    :hover {
        color: ${(props) => props.theme.colorActive};
        text-decoration: underline;
    };
    :active {
        color: ${(props) => props.theme.colorActive};
        text-decoration: underline;
    };
    ::after {
        content: ${(props) => props.active ? "" : "none"};
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translate(0px, -50%);
        width: 23px;
        height: 24px;
        background-image: url(${(props) => props.theme.image});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right center;
    };
`;

const ButtonListItem = styled.li`
    margin: 0px;
    width: 100%;
    padding: 10px 0px;
    height: 100%;
    display: list-item;
    text-align: -webkit-match-parent;
    @media screen and (min-width: 1050px) {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        padding: 0px;
        text-align: left;
    }
`;

const ButtonList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px;
    margin: 0px;
    list-style: none;
    @media screen and (min-width: 1050px) {
        height: 100%;
        margin: 0px;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
    }
`;

export default NavList;