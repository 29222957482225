import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

interface props {
    text?: string;
    onClick?: () => void;
}

const WalletConnectButton: React.FC<props> = ({ text, onClick }) => {
    const { color, font } = useContext(ThemeContext);

    const theme = {
        light: {
            primary: color.theme.light.primary,
            secondary: color.theme.light.secondary
        },
        font: { 
            size: {
                    24: font.size[24],
                    20: font.size[20],
                    18: font.size[18],
                    16: font.size[16],
                    14: font.size[14],
                    12: font.size[12]
                },
            weight: {
                    0: "normal",
                    400: color.theme.light.font.weight[0],
                    500: color.theme.light.font.weight[1],
                    600: color.theme.light.font.weight[4],
                },
            },
        color: {
            primary: color.theme.light.primary,
            secondary: color.theme.light.secondary,
            white: color.white,
            offWhite: color.offWhite,

            tranWhite: {
                16: color.tranWhite[16]
            },
            background: {
                0: "transparent",
                1: "#5f687c",
                2: "#f3ce8c",
            },
            border: {
                0: color.theme.light.primary,
            },
        },
        borderWidth: color.theme.light.borderWidth.small,
        metamaskButtonHeight: "48px",
    }

    return (
            <StyledButton theme={theme} onClick={onClick} >
                <StyledDiv theme={theme}>
                    <GhostDiv theme={theme}>
                        {text}
                    </GhostDiv>
                    <TextDiv theme={theme}>
                        {text}
                    </TextDiv>
                </StyledDiv>
            </StyledButton>
    )
};

interface ThemeProps {
    theme: any;
}

const StyledDiv = styled.div<ThemeProps>`
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    text-decoration: none;
    outline: none;
    font-weight: ${(props) => props.theme.font.weight[600]};
    font-size: ${(props) => props.theme.font.size[16]};
`;

const TextDiv = styled.div<ThemeProps>`
    text-decoration: none;
    outline: none;
    display: block;
    opacity: 1;
    color: ${(props) => props.theme.light.secondary};
    font-weight: ${(props) => props.theme.font.weight[600]};
    webkit-transition: all 400ms ease;
    transition: all 400ms ease;
`;


const GhostDiv = styled.div<ThemeProps>`
    position: absolute;
    top: -80%;
    opacity: 0;
    color: ${(props) => props.theme.light.primary};
    font-weight: ${(props) => props.theme.font.weight[600]};
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
`;


const StyledButton = styled.button<ThemeProps>`
    width: 100%;
    text-decoration: none;
    padding: 0px 18px;
    cursor: pointer;
    z-index: 1;
    background-color: ${(props) => props.theme.light.primary};
    -webkit-box-align: center;
    align-items: center;
    font-size: ${(props) => props.theme.font.size[16]};
    font-weight: ${(props) => props.theme.font.weight[600]};
    margin-left: ${(props) => props.theme.leftMargin};
    position: relative;
    border-radius: 0.3em;
    border: solid ${(props) => props.theme.borderWidth} ${(props) => props.theme.light.primary};
    display: inline-flex;
    outline: none;
    -webkit-box-pack: center;
    justify-content: center;
    height: ${(props) => props.theme.metamaskButtonHeight};
    transition: all 500ms cubic-bezier(.19, 1, .22, 1);
    font-family: inherit;
    :hover {
        background: ${(props) => props.theme.light.secondary};
        border: solid ${(props) => props.theme.borderWidth} ${(props) => props.theme.light.primary};
    };
    :hover ${TextDiv} {
        opacity: 0;
        transform: translateY(80%);
    };
    :hover ${GhostDiv} {
        opacity: 1;
        transform: translateY(80%);
    }
`;

export default WalletConnectButton;
