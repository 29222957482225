import React from 'react';
import useEagerConnect from '../../hooks/useEagerConnect';
import Nav from '../Nav/Nav';

interface PageProps {
  children: React.ReactNode;
}

const Page: React.FC<PageProps> = ({ children }) => {
useEagerConnect();

  return (
    <div style={{ position: 'relative', minHeight: '100vh', background: "transparent" }}>
      <Nav />
        { children }
    </div>
  );
};

export default Page;
