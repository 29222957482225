import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import docs from "../../../../assets/img/docs.svg";
import twitter from "../../../../assets/img/twitter.svg";
import discord from "../../../../assets/img/discord.svg";

interface Props {
    docsHref?: string;
    twitterHref?: string;
    discordHref?: string;
    open?: boolean
    idDropdown: string;
    idButton: string;
    onClick: () => void;
}

const HeaderDropdownMore: React.FC<Props> = ({ onClick, open, idDropdown, idButton,  docsHref, twitterHref, discordHref }) => {
    const { color, font} = useContext(ThemeContext);

    const theme ={
        img: {
            background: color.theme.light.header.priceBackground,
            fillBase: color.theme.light.fillBase,
            fillHover: color.theme.light.white,
            backgroundHover: color.theme.light.headerDropdown.backgroundHover
        },
        dropdown: {
            background: color.theme.light.altPrimary,
        },
        listItem: {
            color: color.theme.light.header.text,
            colorFocus: color.theme.light.white,
            fontSize: font.size[16]
        }
    }

  return (
      <HeaderMoreButton>
          <MoreImgDiv id={idButton} theme={theme}
              onClick={onClick} >
              <MoreImg width="21" height="5" viewBox="0 0 21 5">
                  <path d="M2.5 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zm8 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zm8 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5z" fillRule="evenodd" />
              </MoreImg>
          </MoreImgDiv>
          <Dropdown id={idDropdown} open={open} theme={theme}>
              <List >
                  <MoreListItem>
                      <MoreListItemLink
                          href={docsHref} rel="noreferrer" target="_blank"
                          theme={theme}
                           >
                          <MoreListItemImg src={docs} />
                          Documentation
                      </MoreListItemLink>
                  </MoreListItem>

                  <MoreListItem>
                      <MoreListItemLink
                          href={twitterHref} rel="noreferrer" target="_blank"
                          theme={theme}
                          >
                          <MoreListItemImg src={twitter} />
                          Twitter
                      </MoreListItemLink>
                  </MoreListItem>

                  <MoreListItem>
                      <MoreListItemLink
                          href={discordHref} rel="noreferrer" target="_blank"
                          theme={theme}
                          >
                          <MoreListItemImg src={discord} />
                          Discord
                      </MoreListItemLink>
                  </MoreListItem>
              </List>
          </Dropdown>
      </HeaderMoreButton>
  )
};

const MoreImg = styled.svg`
    display: inline-block;
    border: none;
`

interface ThemeProps {
    theme?: any;
}
const MoreImgDiv = styled.div<ThemeProps>`
    display: none;
    @media screen and (min-width: 1050px) {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        height: 46px;
        width: 46px;
        background-color: ${(props) => props.theme.img.background};
        border-radius: 0.5em;
        cursor: pointer;
        fill: ${(props) => props.theme.img.fillBase};
    };
    :hover {
        fill: ${(props) => props.theme.img.fillHover};
        background-color: ${(props) => props.theme.img.backgroundHover};
    }
`

const HeaderMoreButton = styled.div`
    display: none;
    @media screen and (min-width: 1050px) {
        display: flex;
        height: 46px;
        width: 46px;
        border-radius: 0.5em;
        cursor: pointer;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        background-color: rgb(16, 21, 28);
    };
`

interface DropdownProps {
    open: boolean;
    theme: any;
}

const Dropdown = styled.div<DropdownProps>`
    margin-top: 10px;
    min-width: auto;
    opacity: ${(props) => props.open === true ? 1 : 0};
    transform: scaleY(${(props) => props.open === true ? 1 : 0});
    transition: all 0.2s linear 0s;
    transform-origin: center top;
    box-shadow: rgb(0 0 0 / 19%) 0px 5px 25px 0px;
    background-color: ${(props) => props.theme.dropdown.background};
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.dropdown.background};
    padding: 18px 19px 13px;
    top: 100%;
    right: 0px;
    z-index: ${(props) => props.open === true ? 100 : -1};
    position: absolute;
    display: block;
`


interface MoreListItemImgProps {
    image?: any;
}

const MoreListItemImg = styled.img<MoreListItemImgProps>`
    width: 16px;
    margin-right: 14px;
`;

interface ColorFontProps {
    color?: string;
    colorFocus?: string;
    fontSize?: string;
    theme: any;
}

const MoreListItemLink = styled.a<ColorFontProps>`
    font-size: ${(props) => props.theme.listItem.fontSize };
    font-weight: normal;
    color: ${(props) => props.theme.listItem.color };
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    transition: all 0.2s ease-in-out 0s;
    text-decoration: none;
    filter: contrast(0.5);
    :visited {
        text-decoration: none;
    };
    :hover {
        color: ${(props) => props.theme.listItem.colorFocus };
        filter: contrast(1);
    };
`;

const MoreListItem = styled.li`
    padding: 10px 0px;
    margin: 0px;
    display: list-item;
    text-align: -webkit-match-parent;
`;

const List = styled.ul`
    padding: 0px;
    margin: 0px;
    list-style: none;
`;


export default HeaderDropdownMore;