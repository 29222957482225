export const font = {
    size: {
        xsmall: "12px",
        small: "14px",
        normal: "16px",
        large: "18px",
        xlarge: "20px",
        xxlarge: "22px",
        xxxlarge: "24px",
        10: "10px",
        12: "12px",
        13: "13px",
        14: "14px",
        15: "15px",
        16: "16px",
        18: "18px",
        20: "20px",
        22: "22px",
        24: "24px",
        26: "26px",
        28: "28px",
        36: "36px",

    },
    weight: {
        400: "400",
        500: "500",
        600: "600",
        700: "700",
    }
  }
  