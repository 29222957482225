import { useEffect, useState } from 'react';
import useLiquidFinance from './useLiquidFinance';
import useRefresh from './useRefresh';

const useXTokenInEth = () => {
  const [stat, setStat] = useState<string>();
  const liquidFinance = useLiquidFinance();
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    async function fetch(){
      if (!!liquidFinance) {
      try {
        setStat(await liquidFinance.getXTokenInEth());
      }
      catch(err){
        console.error(`getXTokenInEth(): failure ${err}`)
      }
    } else return }
    fetch();
  }, [setStat, liquidFinance, slowRefresh]);

  return stat;
};

export default useXTokenInEth;
