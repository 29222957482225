import React, { useContext, useCallback } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { AlertCircle } from 'react-feather';

const RowNoFlex = styled.div`
  flex-wrap: nowrap;
`;

export default function ErrorPopup({ message, stack }: { message: string; stack: string }) {
  const { color } = useContext(ThemeContext);
  const copyErrorDetails = useCallback(async () => {
    await navigator.clipboard.writeText(`${message}\n${stack}`);
  }, [message, stack]);

  const theme = {
    color: {
      grey: {
        300: color.theme.light.popUps.grey[300],
        500: color.theme.light.popUps.grey[500],
      },
      teal: {
        200: color.theme.light.popUps.teal[200],
        400: color.theme.light.popUps.teal[400],
      },
      primary: color.theme.light.primary,
      secondary: color.theme.light.secondary,
  },
  weight: {
    500: color.theme.light.font.weight[1],
    600: color.theme.light.font.weight[2],
    700: color.theme.light.font.weight[3]
}
}

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        <AlertCircle color="#FF6871" size={24} />
      </div>
      <div>
        <StyledPopupDesc theme={theme} >{message}</StyledPopupDesc><br/>
        <StyledLink theme={theme} onClick={copyErrorDetails} href="#">
          Copy error details
        </StyledLink>
      </div>
    </RowNoFlex>
  );
}

interface ThemeProps {
  theme?: any;
}

const StyledPopupDesc = styled.span<ThemeProps>`
  font-weight: ${(props) => props.theme.weight[600]};
  color: ${(props) => props.theme.color.primary};
  font-family: inherit;
`;

const StyledLink = styled.a<ThemeProps>`
  color: ${(props) => props.theme.color.primary};
  font-family: inherit;
  text-decoration: none;
  font-weight: ${(props) => props.theme.weight[600]};
  :hover {
    text-decoration: underline;
    font-weight: ${(props) => props.theme.weight[700]};
  }
`;
