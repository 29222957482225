import React, { useContext } from 'react';
import { AlertCircle, CheckCircle } from 'react-feather';
import styled, { ThemeContext } from 'styled-components';
//import { useWallet } from 'use-wallet';
import config from '../../config';
import { useWeb3React} from '@web3-react/core';

const RowNoFlex = styled.div`
  flex-wrap: nowrap;
`;

export default function TransactionPopup({
  hash,
  success,
  summary,
}: {
  hash: string;
  success?: boolean;
  summary?: string;
}) {
  //const { chainId } = useWallet();
  const { chainId } = useWeb3React();

  const { color } = useContext(ThemeContext);

  const theme = {
    color: {
      grey: {
        300: color.theme.light.popUps.grey[300],
        500: color.theme.light.popUps.grey[500],
      },
      teal: {
        200: color.theme.light.popUps.teal[200],
        400: color.theme.light.popUps.teal[400],
      },
      primary: color.theme.light.primary,
      secondary: color.theme.light.secondary,
  },
  weight: {
    500: color.theme.light.font.weight[1],
    600: color.theme.light.font.weight[2],
    700: color.theme.light.font.weight[3]
}
}

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        {success ? <CheckCircle color={theme.color.teal[400]} size={24} /> : <AlertCircle color="#FF6871" size={24} />}
      </div>
      <div>
        <StyledPopupDesc theme={theme}>{summary ?? 'Hash: ' + hash.slice(0, 8) + '...' + hash.slice(58, 65)}</StyledPopupDesc> <br/>
        {chainId && (
          <StyledLink theme={theme} target="_blank" href={`${config.blockExplorerUrl}/tx/${hash}`}>
            View on BlockExplorer
          </StyledLink>
        )}
      </div>
    </RowNoFlex>
  );
}

interface ThemeProps {
  theme?: any;
}

const StyledPopupDesc = styled.span<ThemeProps>`
  font-weight: ${(props) => props.theme.weight[600]};
  color: ${(props) => props.theme.color.primary};
  font-family: inherit;
`;

const StyledLink = styled.a<ThemeProps>`
  color: ${(props) => props.theme.color.primary};
  font-family: inherit;
  text-decoration: none;
  font-weight: ${(props) => props.theme.weight[600]};
  :hover {
    text-decoration: underline;
    font-weight: ${(props) => props.theme.weight[700]};
  }
`;
