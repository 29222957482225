import React, { createContext, useEffect, useState } from 'react';
// import { useWallet } from 'use-wallet';
import LiquidFinance from '../../liquid-finance';
import config from '../../config';
import { useWeb3React } from '@web3-react/core';
// import { getNetworkLibrary } from "../../connectors/index"
export interface LiquidFinanceContext {
  //variable? syntax means that the varaiable is optional and can be left as undefined
  liquidFinance?: LiquidFinance;
}

// function<type_declatation>({_variablesName: _variableType)
// error resulting from tscongig.json set to true changed to "strictNullChecks": false
export const Context = createContext<LiquidFinanceContext>({ liquidFinance: null  });
// const variableName: type = () => {}
export const LiquidFinanceProvider: React.FC = ({ children }) => {
  //const { ethereum, account } = useWallet();  // this is undefined when not connected to wallet, gets context from UseWalletProvider
  //console.log("ethereum", ethereum)
  //console.log("ethereum", ethereum)
  const { account } = useWeb3React();

   //console.log("connector", window.ethereum)
  //const { provider } = getWeb3ReactContext();

  //const ethereum async()= connector.getProvider()

  const [liquidFinance, setLiquidFinance] = useState<LiquidFinance>();  // this is undefined initially, gets set by useEffect hook
  // const ethereum = getNetworkLibrary()
  //console.log("ethereum", ethereum)
  
  useEffect(() => {
    if (!liquidFinance) {
      const liquid = new LiquidFinance(config);
      if (account) {
        // wallet was unlocked at initialization
        liquid.unlockWallet( window.ethereum, account);
      }
      setLiquidFinance(liquid);
    } else if (account) {
      liquidFinance.unlockWallet( window.ethereum, account);
    }
  }, [account,  window.ethereum, liquidFinance]);

  return <Context.Provider value={{ liquidFinance }}>{children}</Context.Provider>;
};
