import { color } from './colors';
import { font } from "./font";

const theme = {
  borderRadius: {
  12:  "12px",
  15: "15px",
  20: "20px",
  },
  color,
  font,
  siteWidth: {
   sm: 880,
   md: 1000,
   lg: 1200,
  },
  spacing: {
    1: 4,
    2: 8,
    3: 10,
    4: 16,
    5: 24,
    6: 32,
    7: 48,
    8: 64,
  },
  topBarSize: 72,
};

export default theme;
