import React from 'react';
import styled from 'styled-components';
import BurgerIcon from '../../../../assets/img/burgerIcon.svg';

interface Props {
    onClick: () => void;
}

const BurgerButton: React.FC<Props> = ({ onClick }) => {

  return (
        <StyledButton onClick={onClick}>
           <BurgerImg src={BurgerIcon}/>
        </StyledButton>
  )
};


const BurgerImg = styled.img`
    width: 18px;
    cursor: pointer;
    margin-right: 8px;
    padding: 0px;
    border: none;
    background: transparent;
`;

const StyledButton = styled.button`
    display: inline-block;
    cursor: pointer;
    margin-right: 8px;
    padding: 0px;
    border: none;
    background: transparent;
    @media screen and (min-width: 1050px) {
        display: none;
    }
`;

export default BurgerButton;