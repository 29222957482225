import React, { useCallback, useEffect, useContext } from 'react';
import { X } from 'react-feather';
import { useSpring } from 'react-spring';
import styled, { ThemeContext } from 'styled-components';
import { animated } from 'react-spring';
import { PopupContent } from '../../state/application/actions';
import { useRemovePopup } from '../../state/application/hooks';
import TransactionPopup from './TransactionPopup';
import ErrorPopup from './ErrorPopup';

interface ThemeProps {
  theme?: any;
}


export const StyledClose = styled(X)`
  position: absolute;
  right: 10px;
  top: 10px;
  :hover {
    cursor: pointer;
  }
`;

export const Popup = styled.div<ThemeProps>`
  display: inline-block;
  width: 100%;
  padding: 1em;
  background-color: ${(props) => props.theme.color.secondary};
  border: solid ${(props) => props.theme.borderWidth} ${(props) => props.theme.color.primary};
  position: relative;
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 20px;
  padding-right: 35px;
  overflow: hidden;

  @media (max-width: 768px) {
    min-width: 290px;
  }
`;
const Fader = styled.div<ThemeProps>`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 4px;
  background-color: ${(props) => props.theme.color.primary};
`;

const AnimatedFader = animated(Fader);

export default function PopupItem({
  removeAfterMs,
  content,
  popKey,
}: {
  removeAfterMs: number | null;
  content: PopupContent;
  popKey: string;
}) {
  const removePopup = useRemovePopup();
  const removeThisPopup = useCallback(() => removePopup(popKey), [popKey, removePopup]);
  useEffect(() => {
    if (removeAfterMs === null) return undefined;

    const timeout = setTimeout(() => {
      removeThisPopup();
    }, removeAfterMs);

    return () => {
      clearTimeout(timeout);
    };
  }, [removeAfterMs, removeThisPopup]);

  const { color } = useContext(ThemeContext);

  let popupContent;
  if ('txn' in content) {
    const {
      txn: { hash, success, summary },
    } = content;
    popupContent = <TransactionPopup hash={hash} success={success} summary={summary} />;
  }
  if ('error' in content) {
    const {
      error: { message, stack },
    } = content;
    popupContent = <ErrorPopup message={message} stack={stack} />;
  }

  const faderStyle = useSpring({
    from: { width: '100%' },
    to: { width: '0%' },
    config: { duration: removeAfterMs ?? undefined },
  });

  const theme = {
    color: {
      grey: {
        300: color.theme.light.popUps.grey[300],
        500: color.theme.light.popUps.grey[500],
      },
      teal: {
        200: color.theme.light.popUps.teal[200],
        400: color.theme.light.popUps.teal[400],
      },
      primary: color.theme.light.primary,
      secondary: color.theme.light.secondary,
  },
  weight: {
    500: color.theme.light.font.weight[1],
    600: color.theme.light.font.weight[2],
    700: color.theme.light.font.weight[3]
},
borderWidth: color.theme.light.borderWidth.large,
borderRadius: color.theme.light.borderRaidus[2],
}

  return (
    <Popup theme={theme}>
      <StyledClose theme={theme} color={theme.color.primary} onClick={removeThisPopup} />
      {popupContent}
      {removeAfterMs !== null ? <AnimatedFader style={faderStyle} /> : null}
    </Popup>
  );
}
