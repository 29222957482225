import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

interface SlippageButtonsProps {
    slippage?: string;
    setSlippage?: any;
}

const SlippageButtons: React.FC<SlippageButtonsProps> = ({ slippage, setSlippage }) => {
    const { font, color } = useContext(ThemeContext);

    const [focus, setFocus] = React.useState(false);
    const [hoverFocus, setHoverFocus] = React.useState(false);

    const handleSlippage = (slip: string) => {
        setSlippage(slip);
        setFocus(false);
    };

    const isSelectedButtons = (slip: string) => {
        if (slippage.toString() === slip && focus === false) {
            return true
        } else {
            return false
        }
    };

    const isSelectedInput = () => {
        if (((slippage.toString() !== "0.5") && (slippage.toString() !== "1.0") && (slippage.toString() !== "2.0")) || focus === true || hoverFocus === true ) {
            return(true)
        } else {
            return(false)
        }
    };

    const theme = {
        borderRadius: color.theme.light.borderRaidus[2],
        borderWidthSmall: color.theme.light.borderWidth.large,
        borderWidth: color.theme.light.borderWidth.xlarge,
        color: {
            active: color.theme.light.slippageButtons.active,
            inactive: color.theme.light.primary,
            borderActive: color.theme.light.primary,
            borderInactive: color.theme.light.primary,
            backgroundInactive: "transparent",
            backgroundActive: color.theme.light.primary,
        },
        font: font.size.small,
        fontWeight: {
            400: color.theme.light.font.weight[0],
            500: color.theme.light.font.weight[1],
            600: color.theme.light.font.weight[2],
            700: color.theme.light.font.weight[3]
        },
    }

  return (
      <SlipDiv>
          <SlippageButton 
          theme={theme}
              selected={isSelectedButtons("0.5")} onClick={() => handleSlippage("0.5")}>
              {"0.5%"}
          </SlippageButton>
          <SlippageButton
          theme={theme}
              selected={isSelectedButtons("1.0")} onClick={() => handleSlippage("1.0")}>
              {"1%"}
          </SlippageButton>
          <SlippageButton
          theme={theme}
              selected={isSelectedButtons("2.0")} onClick={() => handleSlippage("2.0")}>
              {"2%"}
          </SlippageButton >

          <SlippageInputDiv 
          theme={theme}
            selected={isSelectedInput()} >
              <SlippageInput 
              theme={theme}
                  selected={isSelectedInput()} 
                  type="text"
                  pattern="[0-9]*"
                  value={slippage}
                  maxLength={5}
                  onPaste={(event) => {if (!/^[0-9]{0,2}(\.[0-9]{0,2})?$/.test(event.clipboardData.getData("text"))) {
                    event.preventDefault();
                    window.alert("Pasted Text is in the wrong format. Paste a number less than 100 with up to 2 decimal places")
                }}}
                  onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                      } if (slippage.toString().includes(".") && /\./.test(event.key)) {
                          event.preventDefault();
                      }
                  }}
                  onChange={e => {
                    if (e.target.value === ".") {
                        setSlippage("0.");
                        return;
                    }
                    if (slippage === "" || e.target.value === "") {
                        setSlippage(e.target.value);
                        return;
                    } else if ( !/^[0-9]{0,2}(\.[0-9]{0,2})?$/.test(e.target.value) ) {
                        return;
                    } else {
                        setSlippage(e.target.value)
                    }
                  }
                }
                  onFocus={() => { setFocus(true); }}
                  onMouseOver={() => { setHoverFocus(true); }}
                  onMouseOut={() => { setHoverFocus(false); }}
                  onBlur={() => {
                      if (Number(slippage) > 50 || Number(slippage) < 0.1) {
                          alert("Slippage cannot be more that 50% or equal to or less than 0.1! Slippage has been set to 0.5%");
                          setSlippage("0.5");
                          setFocus(false);
                      }
                  }}
              />
              <PercentDiv theme={theme}
                  selected={isSelectedInput()} >
                  %
              </PercentDiv>
          </SlippageInputDiv>
      </SlipDiv>
);
}; 


const SlipDiv = styled.div`
    display: flex;
    gap: 5px;
    @media screen and (min-width: 992px) {
        gap: 8px;
    };
`;

interface ThemeProps {
    selected: boolean;
    theme?: any;
}

const SlippageInput = styled.input<ThemeProps>`
    cursor: text;
    width: 50px;
    height: 22px;
    background-color: transparent;
    padding: 0px 25px 0px 0px;
    margin: 0px;
    font-size: ${(props) => props.theme.font};
    font-weight: ${(props) => props.theme.fontWeight[700]};
    text-align: center;
    outline: none;
    border: none;
    color: ${(props) => props.selected === true ? props.theme.color.active : props.theme.color.inactive};
`;

const PercentDiv = styled.div<ThemeProps>`
    display: flex;
    position: absolute;
    cursor: default;
    right: -1px;
    height: 24px;
    align-items: center;
    justify-content: center;
    padding: 0px 8px 1px 8px;
    font-size: ${(props) => props.theme.font};
    border-radius: 0px 10px 10px 0px;
    border-left: 2px solid;
    text-align: center;
    outline: none;
    outline-offset: -1px;
    color: ${(props) => props.selected === true ? props.theme.color.active : props.theme.color.inactive};
    border-color: ${(props) => props.selected === true ? props.theme.color.active : props.theme.color.inactive};
`;

const SlippageInputDiv = styled.div<ThemeProps>`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border-radius: ${(props) => props.theme.borderRadius};
    width: fit-content;
    font-size: ${(props) => props.theme.font};
    text-align: right;
    font-weight: ${(props) => props.theme.fontWeight[600]};
    border-style: solid;
    border-width: ${(props) => props.theme.borderWidthSmall};
    border-color: ${(props) => props.theme.color.backgroundActive};
    color: ${(props) => props.selected === true ? props.theme.color.active : props.theme.color.inactive};
    background-color: ${(props) => props.selected === true ? props.theme.color.backgroundActive : props.theme.color.backgroundInactive};
    :hover ${PercentDiv} {
        border-color: ${(props) => props.theme.color.active};
        color: ${(props) => props.theme.color.active};
    };
    :hover {
        color: ${(props) => props.theme.color.active};
        background-color: ${(props) => props.theme.color.backgroundActive};
    };
    :hover ${SlippageInput} {
        color: ${(props) => props.theme.color.active};
    };
`;

const SlippageButton = styled.button<ThemeProps>`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 26px;
    border-style: solid;
    border-width: ${(props) => props.theme.borderWidth};
    border-radius: ${(props) => props.theme.borderRadius};
    padding: 0px 10px 0px 10px;
    width: 50px;
    font-size: ${(props) => props.theme.font};
    font-weight: ${(props) => props.theme.fontWeight[700]};
    line-height: 1;
    background: ${(props) => props.selected === true ? props.theme.color.backgroundActive : props.theme.color.backgroundInactive};
    color: ${(props) => props.selected === true ? props.theme.color.active : props.theme.color.inactive};
    border-color: ${(props) => props.selected === true ? props.theme.color.borderActive : props.theme.color.borderInactive};
    :hover {
        color: ${(props) => props.theme.color.active};
        border-color: ${(props) => props.theme.color.borderActive};
        background: ${(props) => props.theme.color.backgroundActive};
    };
`;

export default SlippageButtons;