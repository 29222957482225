import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import docs from "../../../../assets/img/docs.svg";
import twitter from "../../../../assets/img/twitter.svg";
import discord from "../../../../assets/img/discord.svg";


interface Props {
    open: boolean;
    onClick: () => void;
    docsHref: string;
    twitterHref: string;
    discordHref: string;
}

const SidebarMore: React.FC<Props> = ({ open, onClick, docsHref, twitterHref, discordHref }) => {
    const { color, font} = useContext(ThemeContext);

    return (
        <div >
            <SidebarMoreActive color={color.theme.light.header.text} 
                colorFocus={color.theme.light.white} open={open} onClick={onClick} >
                More
            </SidebarMoreActive>
            <SidebarMoreDropdown open={open} color={color.theme.light.header.text} colorFocus={color.theme.light.white} >
                <SidebarMoreLinks 
                href={docsHref} rel="noreferrer" target="_blank"
                color={color.theme.light.header.text} colorFocus={color.theme.light.white} fontSize={font.size[16]}>
                    <MoreListItemImg src={docs} />
                    Documenation
                </SidebarMoreLinks>
                <SidebarMoreLinks 
                href={twitterHref} rel="noreferrer" target="_blank"
                color={color.theme.light.header.text} colorFocus={color.theme.light.white} fontSize={font.size[16]}>
                    <MoreListItemImg src={twitter} />
                    Twitter
                </SidebarMoreLinks>
                <SidebarMoreLinks 
                href={discordHref} rel="noreferrer" target="_blank"
                color={color.theme.light.header.text} colorFocus={color.theme.light.white} fontSize={font.size[16]}>
                    <MoreListItemImg src={discord} />
                    Discord
                </SidebarMoreLinks>
            </SidebarMoreDropdown>
        </div>
    )
};

interface MoreListItemImgProps {
    image?: any;
}

const MoreListItemImg = styled.img<MoreListItemImgProps>`
    width: 16px;
    margin-right: 14px;
`;

interface ColorFontProps {
    color?: string;
    colorFocus?: string;
    fontSize?: string;
}

const SidebarMoreLinks = styled.a<ColorFontProps>`
    margin: 0px 0px 18px;
    font-size: ${(props) => props.fontSize};
    font-weight: normal;
    cursor: pointer;
    color: ${(props) => props.color};
    filter: contrast(0.5);
    text-decoration: none;
    :hover {
        color: ${(props) => props.colorFocus};
        filter: contrast(1);
    };
    :visited {
        text-decoration: none;
    };
`;

interface SidebarMoreDropdownProps {
    open: boolean;
    color: string;
    colorFocus: string;
};
const SidebarMoreDropdown = styled.div<SidebarMoreDropdownProps>`
    display: ${(props) => props.open !== true ? "none" : "flex"};
    flex-direction: column;
    padding: 5px 0px 0px 20px;
    transform-origin: center top;
    transform: scaleY(${(props) => props.open !== true ? "0" : "1"};);
    opacity: ${(props) => props.open !== true ? "0" : "1"};
    transition: transform 0.2s linear 0s;
    height: ${(props) => props.open !== true ? "0px" : "fit-content"};
    color: ${(props) => props.open !== true ? props.color : props.colorFocus };
    @media screen and (min-width: 992px) {
        display: none;
    };
`;

const SidebarMoreActive = styled.div<SidebarMoreDropdownProps>`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: ${(props) => props.open !== true ? props.color : props.colorFocus};
    :hover {
        color: ${(props) => props.colorFocus };
    };
    @media screen and (min-width: 992px) {
        display: none;
    };
`;

export default SidebarMore;