//import { useWallet } from 'use-wallet';

/*
useEagerConnect automatically reconnects to your wallet when page reloads.

It does this by storing the connector information in the browers local storage
If the user has not connected through their wallet yet, the site will not connect
*/

const useEagerConnect = () => {
  // const { account, connect, connector } = useWallet();
  
  // // console.log("account", account)
  // // console.log("connector", connector)
  // // console.log("window.localStorage.getItem('connectorId')", !window.localStorage.getItem('connectorId'))
  // if ((account  !== null) && (!!window.localStorage.getItem('connectorId') === false)) {
  //   window.localStorage.setItem('connectorId', connector);
  //   // console.log("ran")
  //   //console.log("window.localStorage.setItem('connectorId', connector)", window.localStorage.getItem('connectorId', connector))
  // }

  // useEffect(() => {
  //   const connectorId = window.localStorage.getItem('connectorId');
  //   if (connectorId && !account) {
  //     connect(connectorId);
  //   }
  //  // console.log("connectorId", connectorId, "!account", !!account)
  // }, [connect, account, connector]);
};

export default useEagerConnect;
