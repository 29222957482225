// Captures 0x + 4 characters, then the last 4 characters.
const truncateRegex = /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{3})$/;
const truncateRegexLong = /^(0x[a-zA-Z0-9]{5})[a-zA-Z0-9]+([a-zA-Z0-9]{5})$/;

/**
 * Truncates an ethereum address to the format 0x0000…0000
 * @param address Full address to truncate
 * @returns Truncated address
 */

const truncateEthAddress = (address: string, long?: boolean) => {
  const match = address.match(long ? truncateRegexLong : truncateRegex);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export default truncateEthAddress;
