// import { ChainId } from '@sushiswap-core/sdk';
import { Configuration } from './liquid-finance/config';
import { BankInfo } from './liquid-finance'; 

const configurations: { [env: string]: Configuration } = {
  production: {
    chainId: 42161,
    networkName: 'Arbitrum',
    blockExplorerUrl: 'https://arbiscan.io/',
    defaultProvider: "https://arb1.arbitrum.io/rpc",
    deployments: require('./liquid-finance/deployments/deployments.testing.json'),
    externalTokens: {
      WETH: ['0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', 18],
      USDC: ['0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8', 6],
      LDO: ["0x13Ad51ed4F1B7e9Dc168d8a00cB3f4dDD85EfA60", 18],
      BAL: ["0x040d1EdC9569d4Bab2D15287Dc5A4F10F56a56B8", 18],
      BTC: ["0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f", 8],
      wstETH: ["0x5979D7b546E38E414F7E9822514be443A4800529", 18],
      'USDC-WETH-LP': ['0x905dfCD5649217c42684f23958568e533C711Aa3', 18],
      'LIQD-WETH-LP': ['0x5dCF474814515B58ca0CA5e80bbB00d18C5B5cF8', 18], //
      'WETH-lqETH-LP': ['0xB6a0ad0f714352830467725e619ea23E2C488f37', 18],
      'WETH-wstETH-LP': ['0x62bc45B7ea9B854FaccE545a267295D8eB4a58F7', 18],
      'WETH-wstETH-LP2': ['0x62bc45B7ea9B854FaccE545a267295D8eB4a58F7', 18],
      // 'WETH-BAL-LP': ['0xB6a0ad0f714352830467725e619ea23E2C488f37', 18],
      'WETH-LDO-LP': ['0x25aB7DC4dDcACB6fE75694904db27602175245f1', 18],
      'xCal-lqETH-WETH-LP': ['0x341a16FD1399df0990a89C0a285b97e11E749e66', 18],
      'xCal-BTC-lqETH-LP': ['0x02Cf9AB55Be7B64545aCd6E297c66375E0806b3F',18],
      'btcEth_LP': ['0x149e36E72726e0BceA5c59d40df2c43F60f5A22D', 18]
    },
    refreshInterval: 10000,
  },
  development: {
    chainId: 42161,
    networkName: 'Arbitrum',
    blockExplorerUrl: 'https://arbiscan.io/',
    defaultProvider: "https://arb1.arbitrum.io/rpc",
    deployments: require('./liquid-finance/deployments/deployments.testing.json'),
    externalTokens: {
      WETH: ['0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', 18],
      USDC: ['0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8', 6],
      LDO: ["0x13Ad51ed4F1B7e9Dc168d8a00cB3f4dDD85EfA60", 18],
      BAL: ["0x040d1EdC9569d4Bab2D15287Dc5A4F10F56a56B8", 18],
      BTC: ["0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f", 8],
      wstETH: ["0x5979D7b546E38E414F7E9822514be443A4800529", 18],
      'USDC-WETH-LP': ['0x905dfCD5649217c42684f23958568e533C711Aa3', 18],
      'LIQD-WETH-LP': ['0x5dCF474814515B58ca0CA5e80bbB00d18C5B5cF8', 18], //
      'WETH-lqETH-LP': ['0xB6a0ad0f714352830467725e619ea23E2C488f37', 18],
      'WETH-wstETH-LP': ['0x62bc45B7ea9B854FaccE545a267295D8eB4a58F7', 18],
      'WETH-wstETH-LP2': ['0x62bc45B7ea9B854FaccE545a267295D8eB4a58F7', 18],
      //'WETH-BAL-LP': ['0xB6a0ad0f714352830467725e619ea23E2C488f37', 18],
      'WETH-LDO-LP': ['0x25aB7DC4dDcACB6fE75694904db27602175245f1', 18],
      'xCal-lqETH-WETH-LP': ['0x341a16FD1399df0990a89C0a285b97e11E749e66', 18],
      'xCal-BTC-lqETH-LP': ['0x02Cf9AB55Be7B64545aCd6E297c66375E0806b3F',18],
      'btcEth_LP': ['0x149e36E72726e0BceA5c59d40df2c43F60f5A22D', 18]
    },
    refreshInterval: 10000, // doesn't do anything
  },
};

export const bankDefinitions: { [contractName: string]: BankInfo } = {

  yTokenWethPool: {
      name: "yTokenFarm",
      poolId: 1,
      sectionInUI: 0,
      contract: "yTokenWethChef",
      depositTokenName: 'LIQD-WETH-LP',
      earnTokenName: "LIQD",
      symbol0: "LIQD",
      symbol1: "ETH",
      finished: false,
      sort: 0,
      closedForStaking: false,
      addLpLink: 'https://app.sushi.com/legacy/add/ETH/0x93C15cd7DE26f07265f0272E0b831C5D7fAb174f?chainId=42161',
      removeLpLink: 'https://app.sushi.com/legacy/remove/0x93C15cd7DE26f07265f0272E0b831C5D7fAb174f/ETH?chainId=42161',
      projectName: "Liquid Finance"
    },
  xTokenWethPool: {
      name: "xTokenFarm",
      poolId: 0,
      sectionInUI: 1,
      contract: "xTokenWethChef",
      depositTokenName: "WETH-lqETH-LP",
      earnTokenName: "LIQD",
      symbol0: "lqETH",
      symbol1: "ETH",
      finished: false,
      sort: 1,
      closedForStaking: false,
      addLpLink: "https://app.sushi.com/legacy/add/ETH/0x73700aeCfC4621E112304B6eDC5BA9e36D7743D3?chainId=42161",
      removeLpLink: "https://app.sushi.com/legacy/remove/0x73700aeCfC4621E112304B6eDC5BA9e36D7743D3/ETH?chainId=42161",
      projectName: "Liquid Finance"
    }
};

export default configurations[process.env.NODE_ENV || 'development'];
