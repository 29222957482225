import React from 'react';
import Header from "./Header";
// import styled, { ThemeContext } from 'styled-components';
// import InputBN from "../Input/InputBN";
// import { SolidButton } from "../Buttons";
// import cross from "../../assets/img/cross.svg";

// import { BigNumber, utils } from "ethers";
// import useLiquidFinance from '../../hooks/useLiquidFinance';
// import { useTransactionAdder } from "../../state/transactions/hooks";
// import { useAddPopup } from '../../state/application/hooks';
// import useUserInfo from "../../hooks/useUserInfo";

const Nav: React.FC = () => {
  // const { font, color } = useContext(ThemeContext);
  // const finance = useLiquidFinance();
  // const userInfo = useUserInfo();
  // const addTransaction = useTransactionAdder();
  // const addPopup = useAddPopup();

  // const [close, setClose] = useState(true);
  // const [pending, setPending] = useState(false);

  // const ethBalanceCollect = useMemo(() => (!userInfo ? BigNumber.from(0): userInfo.ethBalance),[userInfo])
  // const xTokenBalanceCollect = useMemo(() => (!userInfo ? BigNumber.from(0): userInfo.xTokenBalance),[userInfo])
  // const yTokenBalanceCollect = useMemo(() => (!userInfo ? BigNumber.from(0): userInfo.yTokenBalance),[userInfo])

  // // display strings
  // const ethBalanceCollectString = utils.formatUnits(ethBalanceCollect, 18)
  // const xTokenBalanceCollectString = utils.formatUnits(xTokenBalanceCollect, 18)
  // const yTokenBalanceCollectString = utils.formatUnits(yTokenBalanceCollect, 18)

  // const transactionPendingOnlyClose = useCallback(
  //   (promise, summary, setPending, setClose) => {
  //     promise
  //       .then((tx: any) => {
  //         if ( setPending ) {
  //           setPending(false) 
  //         };
  //         setClose(true)
  //         addTransaction(tx, { summary })
  //       })
  //       .catch((err: any) => {
  //         if ( setPending ) {
  //           setPending(false) 
  //         };
  //         setClose(true)
  //         if (err.message.includes('User denied')) {
  //           // User denied transaction signature on MetaMask.
  //           return;
  //         } 
  //         let message = `Unable to ${summary[0].toLowerCase()}${summary.slice(1)}`;
  //         console.error(`${message}: ${err.message || err.stack}`);
  //         addPopup({ error: { message, stack: err.message || err.stack } });
  //       });
  //   },
  //   [addPopup, addTransaction],
  // );

  // const handleCollect = useCallback(
  //   (setPending, setClose) => {
  //     if ( setPending ) {
  //       setPending(true) 
  //     };
  //     transactionPendingOnlyClose(
  //       finance.collect(),
  //       `Collected tokens from pool contract`,
  //       setPending,
  //       setClose
  //     );
  //   },
  //   [finance, transactionPendingOnlyClose],
  // );

  // useEffect(() => {

  //   if (ethBalanceCollect.eq(0) && xTokenBalanceCollect.eq(0) && yTokenBalanceCollect.eq(0)) {
  //     setClose(true)
  //   }
  // },[ethBalanceCollect, xTokenBalanceCollect, yTokenBalanceCollect])

  // logic
  // const buttonText = ethBalanceCollect.eq(0) && xTokenBalanceCollect.eq(0) && yTokenBalanceCollect.eq(0) ? "Tokens Collected" : "Collect Tokens"

// const theme1 = {
//   backgroundColor: color.header.background,
//   trans: color.theme.light.secondary,
//   backgroundColorLight:  color.theme.light.header.backgroundLight,
//   backgroundTrans: color.theme.light.transparent[27],
//   grey: color.theme.light.header.grey,
//   secondary: color.theme.light.secondary,
//   border: `solid ${color.theme.light.borderWidth.large} ${color.theme.light.primary}`,
//   borderRadius: color.theme.light.borderRaidus[1],
// }

// const theme = {
//   warning: color.input.error,
//   dottedLineColor: color.theme.light.primaryTrans,
//   borderRadius: color.theme.light.borderRaidus[2],
//   text: {
//       size: {
//           small: "14px",
//           large: "16px"
//       },
//       weight: color.theme.light.font.weight[2],
//       color: color.theme.light.primary,
//   },
//   plusDiv: {
//       fontSize: font.size[24],
//       fontWeight: color.theme.light.font.weight[1],
//       borderRadius: color.theme.light.borderRaidus[2],
//       borderWidth: color.theme.light.borderWidth.large,
//       color: {
//           border: color.theme.light.primary,
//           background: color.theme.light.secondary,
//           color: "red",
//       }
//   },
//   arrowBox: {
//       padding: "8px 8px",
//       borderWidth: "0px",
//       color: {
//           border: color.theme.light.primary,
//           background: "transparent" //"rgb(222,209,190)",
//       }
//   },
//   color: {
//       background: color.theme.light.secondary,
//       border: color.theme.light.primary,
//   },
//   box: {
//       color: {
//           border: color.theme.light.primary,
//           background: "transparent",
//       },
//       borderWidth: color.theme.light.borderWidth.large,
//       borderRadius: color.theme.light.borderRaidus[2],
//   },
//   boxTopTitle: {
//       color: color.theme.light.primary,
//       font: {
//           size: "16px",
//           weight: color.theme.light.font.weight[3]
//       }
//   },
//   balanceDiv: {
//       color: color.theme.light.primary,
//       font: {
//           size: "14px",
//           weight: color.theme.light.font.weight[2],
//       }
//   },
//   balanceButton: {
//       color: color.theme.light.primary,
//       fontWeight: color.theme.light.font.weight[2],
//   },
//   inputDiv: {
//       color: {
//           background: "transparent",
//           border: color.theme.light.primary,
//       },
//       borderRadius: color.theme.light.borderRaidus[2],
//       borderWidth: color.theme.light.borderWidth.large,
//   },
//   rewardDiv: {
//       color: color.theme.light.primary,
//       font: {
//           size: "18px",
//           weight: color.theme.light.font.weight[2],
//       },
//   },
//   styledLink: {
//       font: {
//           size: "14px",
//           weight: color.theme.light.font.weight[2],
//       },
//       color: color.theme.light.primary,
//   },
//   styledButton: {
//       border: {
//         width: color.theme.light.borderWidth.large,
//         color: color.theme.light.primary,
//         disabled: color.theme.light.disabled,
//       },
//       background: {
//         disabled: color.theme.light.disabled,
//         active: color.theme.light.primary,
//       },
//       font: {
//         size: "14px",
//         weight: color.theme.light.font.weight[2],
//       },
//       color: {
//         primary: color.theme.light.primary,
//         secondary: color.theme.light.secondary,
//       }
//   },
// }

  return (
    <>
    <Header />
    {/* <Container theme={theme1} close={close} empty={ethBalanceCollect.eq(0) && xTokenBalanceCollect.eq(0) && yTokenBalanceCollect.eq(0) ? true : false}> */}
      {/* Input Section */}
      {/* { !close ?
      <>
        <BoxTopDiv>
          <BoxTopTitle theme={theme} >
            You Will Receive
          </BoxTopTitle>
          <BalanceDiv theme={theme}>
            <CloseButon image={cross} onClick={()=> setClose(true)} />   
          </BalanceDiv>
        </BoxTopDiv>
        <InputDiv theme={theme}>
          <InputInnerDiv >
            <InputBN
              deposit={yTokenBalanceCollectString} depositMax={yTokenBalanceCollect}
             displayOnly={true} symbol={"LIQD"} />
          </InputInnerDiv>
        </InputDiv>
        <InputDiv theme={theme}>
          <InputInnerDiv >
            <InputBN
              deposit={xTokenBalanceCollectString} depositMax={xTokenBalanceCollect}
               displayOnly={true} symbol={"lqETH"} />
          </InputInnerDiv>
        </InputDiv>
        <InputDiv theme={theme}>
          <InputInnerDiv >
            <InputBN
              deposit={ethBalanceCollectString} depositMax={ethBalanceCollect}
              displayOnly={true} symbol={"ETH"} />
          </InputInnerDiv>
        </InputDiv>
        </>
        : <></>
        } */}
        {/* Button */}
        {/* { !close ?
        <> */}
        {/* {pending ? 
        <ButtonDiv close={close}>
        <SolidButton
          size="sm" text={"Pending"} errorText={"Nothing Here"}
          symbol0={"LIQD"}
          disabled={true}
          colorAlert={true}
        />
        </ButtonDiv> :

        <ButtonDiv close={close}>
          <SolidButton
            size="lg" text="Collect" errorText={"Nothing Here"}
            symbol0={"LIQD"}
            disabled={false}
            onClick={() => {
              handleCollect(setPending, setClose);
            }}
          />
        </ButtonDiv>
        } */}
        {/* </>
        : 
        <ButtonDiv close={close}>
          <SolidButton
            size="sm" text={buttonText} errorText={"Nothing Here"}
            symbol0={"LIQD"}
            disabled={ethBalanceCollect.eq(0) && xTokenBalanceCollect.eq(0) && yTokenBalanceCollect.eq(0) ? true : false}
            onClick={() => {
              setClose(false);
            }}
          />
        </ButtonDiv>} */}
    {/* </Container> */}
    </>
  );
};

// interface ThemeProps {
//   theme?: any;
//   disabled?: boolean;
//   warning?: boolean;
//   close?: boolean;
//   empty?: boolean;
// }


// interface CloseButtonProps {
//   image?: any;
// }

// const CloseButon = styled.button<CloseButtonProps>`
// position: absolute;
// z-index: 1;
// top: 22px;
// right: 20px;
// display: flex;
// -webkit-box-align: center;
// align-items: center;
// -webkit-box-pack: center;
// justify-content: center;
// order: 9;
// border: none;
// cursor: pointer;
// background: transparent;
// font-family: inherit;
// padding: 5px;
// min-width: 20px;
// min-height: 20px;
// ::before {
//   content: "";
//   position: absolute;
//   top: 0px;
//   bottom: 0px;
//   width: 15px;
//   height: 15px;
//   background-image: url(${(props) => props.image});
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position: center center;
//   filter: contrast(0.5);
// };
// :hover::before {
//   filter: none;
// }
// `;

// const ButtonDiv = styled.div<ThemeProps>`
//     padding: ${(props) => props.close ? "0px" : "5px 0px 0px"};
//     display: flex;
//     -webkit-box-align: center;
//     align-items: center;
//     -webkit-box-pack: center;
//     justify-content: center;
//     gap: 10px;
//     @media screen and (min-width: 992px) {
//         padding: ${(props) => props.close ? "0px" : "5px 0px 0px"};
//     }
// `;

// const InputInnerDiv = styled.div`
//     display: flex;
//     padding: 8px 12px;
//     border-radius: 0.3em;
//     background-color: transparent;
//     width: 100%;
//     @media screen and (min-width: 992px) {
//         min-width: 0;
//     }
// `;

// const InputDiv = styled.div<ThemeProps>`
//     display: flex;
//     border-radius: ${(props) => props.theme.inputDiv.borderRadius};  
//     border: ${(props) => props.theme.inputDiv.borderWidth} solid ${(props) => props.theme.inputDiv.color.border};
//     background-color: ${(props) => props.theme.inputDiv.color.background};
//     @media screen and (min-width: 992px) {
//         min-width: 0;
//     };
//     :not(:last-child) {
//         margin-bottom: 10px;
//     };
// `;

// const BalanceDiv = styled.div<ThemeProps>`
//     display: block;
//     font-size: ${(props) => props.theme.balanceDiv.font.size};
//     font-weight: ${(props) => props.theme.balanceDiv.font.weight};
//     color: ${(props) => props.theme.balanceDiv.color};
// `;



// const BoxTopTitle = styled.div<ThemeProps>`
//     display: flex;
//     -webkit-box-align: center;
//     align-items: center;
//     -webkit-box-pack: center;
//     justify-content: center;
//     font-size: ${(props) => props.theme.boxTopTitle.font.size};
//     font-weight: ${(props) => props.theme.boxTopTitle.font.weight};
//     color: ${(props) => props.theme.boxTopTitle.color};
// `;


// const BoxTopDiv = styled.div`
//     margin-bottom: 10px;
//     display: flex;
//     -webkit-box-align: center;
//     align-items: center;
//     -webkit-box-pack: justify;
//     justify-content: space-between;
// `;

// const Container = styled.div<ThemeProps>`
//     display: ${(props) => props.empty ? "block" : "block"};
//     position: absolute;
//     -webkit-box-align: center;
//     align-items: center;
//     border-radius: ${(props) => props.theme.borderRadius}
//     border: ${(props) => props.close ? "none" : props.theme.border};
//     max-width: 200px;
//     z-index: 10;
//     right: 0;
//     top: 80px;
//     margin: 15px 15px 15px 15px;
//     padding: ${(props) => props.close ? "0px 0px" : "20px 20px"};
//     background-color: ${(props) => props.theme.trans};
// `;

export default Nav;
