import React, { Suspense, lazy } from 'react';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import { Provider } from 'react-redux';
// import { UseWalletProvider } from 'use-wallet';
import './App.css';
import store from './state';
import Updaters from './state/Updaters';
import LiquidFinanceProvider from './contexts/LiquidFinanceProvider';
import ModalsProvider from './contexts/Modals';
import { RefreshContextProvider } from './contexts/RefreshContext';
import BanksProvider from './contexts/Banks';
import muiTheme from './muiTheme';
import { ThemeProvider as TP } from '@mui/material';
import theme from './theme';
import { ThemeProvider as TP1 } from 'styled-components';
import Loader from './components/Loader';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { Page } from './components/pageLayout';
import Popups from './components/Popups';
import { NetworkContextName } from './constants';
import getLibrary from './utils/getLibrary';
import Web3ReactManager from './components/Web3ReactManager';

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

const Home = lazy(() => import('./views/Home'));
const Mint = lazy(() => import('./views/Mint'));
const Redeem = lazy(() => import('./views/Redeem'));
const Farms = lazy(() => import('./views/Farms'));
const Staking = lazy(() => import('./views/Staking'));
const Bonds = lazy(() => import('./views/Bonds'));
// const Whitelist = lazy(() => import('./views/Whitelist'));
const Arb = lazy(() => import('./views/Arbitrage'));

const NoMatch = () => (
  <h3 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
    URL Not Found. <a href="/">Go back home.</a>
  </h3>
);

function App() {

  return (
    <>
      <Providers>
        <Page>
          <Router>
            <Suspense fallback={<Loader />}>
            <Web3ReactManager>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/Mint">
                  <Mint />
                </Route>
                <Route path="/Redeem">
                  <Redeem />
                </Route>
                <Route path="/Farms">
                  <Farms />
                </Route>
                <Route path="/Staking">
                  <Staking />
                </Route>
                <Route path="/Bonds">
                  <Bonds />
                </Route>
                {/* <Route path="/Whitelist">
                  <Whitelist />
                </Route> */}
                <Route path="/Arbitrage">
                  <Arb />
                </Route>
                <Route path="*">
                  <NoMatch />
                </Route>
              </Switch>
              </Web3ReactManager>
            </Suspense>
          </Router>
        </Page>
      </Providers>
    </>
  );
}

const Providers: React.FC = ({ children }) => {
  return (
    <TP1 theme={theme}>
      <TP theme={muiTheme}>
        <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
        {/* <UseWalletProvider
          connectors={{
            injected: {
              chainId: [config.chainId]
            },
            walletconnect: {
              chainId: [config.chainId],
              rpcUrl: config.defaultProvider
            },
            walletlink: {
              chainId: [config.chainId],
              url: config.defaultProvider,
              appName: 'Liquid Finance',
              appLogoUrl: assets.logo,
            },
          }
          }
        > */}
         {/* <UseWalletProvider
          chainId={config.chainId} 
          connectors={{walletconnect: { rpcUrl: config.defaultProvider }, 
              walletlink: {
                url: config.defaultProvider,
                appName: 'Liquid Finance',
                appLogoUrl: assets.logo,
              },
            }
          }
        > */}
          <Provider store={store}>
            <Updaters />
            <RefreshContextProvider>
              <LiquidFinanceProvider>
                <ModalsProvider>
                  <BanksProvider>
                    <>
                      <Popups />
                      {children}
                    </>
                  </BanksProvider>
                </ModalsProvider>
              </LiquidFinanceProvider>
            </RefreshContextProvider>
          </Provider>
        {/* </UseWalletProvider> */}
        </Web3ProviderNetwork>
        </Web3ReactProvider>
      </TP>
    </TP1>
  );
};


export default App;
