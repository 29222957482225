import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import WalletConnectModal from "../../WalletConnectModal/WalletConnectModal";
import truncateEthAddress from "../../../utils/truncateEthAddress";
import AccountModal from "./AccountModal";
import { WalletConnect } from "../../Buttons";
import useRefresh from "../../../hooks/useRefresh";
import { useActiveWeb3React } from '../../../hooks/useActiveWeb3React';

interface WalletConnectButtonProps {
    onClick?: any;
}

const WalletConnectButton: React.FC<WalletConnectButtonProps> = ({ onClick }) => {
  const { fastRefresh } = useRefresh();
  const { color, font} = useContext(ThemeContext);
  // const { account } = useWallet();
  const { account } = useActiveWeb3React();
  
  const [isWalletProviderOpen, setWalletProviderOpen] = useState(false);
  const [isWalletInfoModalOpen, setWalletInfoModalOpen] = useState(false);
  //   const [onPresentAccountModal] = useModal(<AccountModal />);

  const handleWalletProviderOpen = () => setWalletProviderOpen(true);
  const handleWalletProviderClose = () => setWalletProviderOpen(false);
  const handleWalletInfoModalOpen = () => setWalletInfoModalOpen(true);
  const handleWalletInfoModalClose = () => setWalletInfoModalOpen(false);

 useEffect(() => {
 },[fastRefresh])

  const theme = {
    font: {
        size: {
            16: "16px"
        }
    },
    color: {
        white: color.theme.light.white,
        background: color.theme.light.header.priceBackground,
        backgroundHover: color.theme.light.headerDropdown.backgroundHover
    },
    weight: {
        500: color.theme.light.font.weight[1],
        600: color.theme.light.font.weight[2],
        700: color.theme.light.font.weight[3]
    },
    borderRadius: color.theme.light.borderRaidus[2],
}

    return (
        <>
        {!account ? (

            <WalletConnect text="CONNECT WALLET" onClick={handleWalletProviderOpen} />
            ) : ( <>
            <HeaderWalletButton theme={theme} disabled={false} onClick={handleWalletInfoModalOpen}>
                {/* <HeaderWalletImg style={{marginRight: "8px"}} src={TombstoneWallet}/> */}
                <HeaderWalletSpan theme={theme} color={color.white} fontSize={font.size[16]}> 
                    {truncateEthAddress(account)}
                </HeaderWalletSpan>
            </HeaderWalletButton>
             </> )}
            <WalletConnectModal open={isWalletProviderOpen} handleClose={handleWalletProviderClose} />
            <AccountModal open={isWalletInfoModalOpen} handleClose={handleWalletInfoModalClose} />
        </>
    );
};

interface Theme {
    theme?: any;
}


const HeaderWalletSpan = styled.span<ColorFontProps>`
    display: inline;
    font-size: ${(props) => props.fontSize};
    font-weight: ${(props) => props.theme.weight[500]};
    color: ${(props) => props.color};
`

interface ColorFontProps {
    theme?: any;
    color?: string;
    colorFocus?: string;
    fontSize?: string;
}

// const HeaderWalletImg = styled.img`
//     display: inline-block;
//     border-radius: 30%;
//     border: none;
//     width: 18px;
//     height: 22px;
//     @media screen and (min-width: 992px) {
//         margin-right: 8px;
//     };
// `

const HeaderWalletButton = styled.button<Theme>`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 18px;
    border-radius: 10px;
    background-color: ${(props) => props.theme.color.background};
    font-family: inherit;
    height: 46px;
    border: none;
    :not(:disabled) {
        cursor: pointer;
    };
    @media screen and (min-width: 992px) {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding: 0px 18px;
        height: 46px;
        border-radius: ${(props) => props.theme.borderRadius};
        background-color: ${(props) => props.theme.color.background};
        font-family: inherit;
        border: none;
    };
    :hover {
        background-color: ${(props) => props.theme.color.backgroundHover};
    }
`

export default WalletConnectButton;